import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, RadioGroup, Radio } from '@mui/material'
import { AppContext } from '../Context'

const RadioGroupInput = ({ id, value, name, array }) => {
	const { t } = useTranslation()
	const { handleChange, margin } = useContext(AppContext)
	const [chosenValue, setChosenValue] = useState(value)

	//Here we are using state to solve this issue
	//A component is changing an uncontrolled input of type text to be controlled error in ReactJS
	useEffect(() => {
		setChosenValue(value)
	}, [value])
	return (
		<RadioGroup
			id={id}
			margin={margin}
			value={chosenValue === undefined ? '' : chosenValue}
			name={name}
			onChange={handleChange}
		>
			{array.map(({ label, value }, i) => (
				<FormControlLabel
					key={i}
					value={value}
					control={<Radio />}
					label={t(label)}
				/>
			))}
		</RadioGroup>
	)
}

export default RadioGroupInput

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'
import Header from '../../UI/Header'

const AccessError = () => {
	const currentUser = useCurrentUser()
	const { t } = useTranslation()
	return (
		<>
			<Header hideButtons />
			<div style={{ padding: '10px 0 0' }}>
				<h3>{currentUser?.email}</h3>
				<p>{t('access_error')}</p>
				<p>{t('access_error_contact')}</p>
			</div>
		</>
	)
}

export default AccessError

import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import GeneralInfoSummary from './GeneralInfoSummary'
import ComplainantSummary from './ComplainantSummary'
import AffectedPersonSummary from './AffectedPersonSummary'
import SubjectSummary from './SubjectSummary'
import ClosureSummary from './ClosureSummary'

import Header from '../../UI/Header'
import Progress from '../../UI/Progress'

const REPORT_BY_ID = loader('../EditReportForm/graphql/reportById.graphql')

const ReadReport = () => {
	const params = useParams()

	const { data: { reportById = null } = {}, loading } = useQuery(REPORT_BY_ID, {
		variables: {
			id: params.id,
		},
	})

	return (
		<>
			<Header />
			{loading ? (
				<Progress />
			) : (
				reportById && (
					<div>
						<GeneralInfoSummary report={reportById} />
						{reportById.complainant && (
							<ComplainantSummary report={reportById} />
						)}
						{reportById.affectedPerson && (
							<AffectedPersonSummary report={reportById} />
						)}
						<SubjectSummary report={reportById} />
						<ClosureSummary report={reportById} />
					</div>
				)
			)}
		</>
	)
}

export default ReadReport

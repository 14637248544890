/** This function is resposible for filtering all outcomes options based on the selected HrStatus
 * in the Subject section.
 * All the values are hardcoded in src/defaultValues files
 */
import {
	beneficiaryAndCommunityMembersOutcomes,
	dailyWorkersOutcomes,
	incentiveStaffOrVolunteersOutcomes,
	internationalMobileAndHqAndBoardMemberStaffOutcomes,
	locallyHiredStaffOutcomes,
	internationalStaff,
	nationalStaff,
	hqStaff,
	dailyWorker,
	incentiveStaff,
	beneficiary,
	careTakers,
	partners,
	communityMember,
	boardMember,
	dependent,
	endOfContract,
	endOfCollaborationWithOcb,
	ndpp,
	volunteers,
	// internationalAndHqStaffGarecAndHrOptions,
	// boardMemberGarecAndHrOptions,
} from '../../../../defaultValues'
import filterOutcomeSelections from './filterOutcomeSelections'

//If the user selected a certain hrStatus in the Subject section
//We use the array we declared in src/default values to exclude some values
//based on GAREC request
const filterOutcomesWithHrStatus = ({
	allOutcomes,
	hrStatus,
	selectedOutcomesArray,
	user,
}) => {
	//NDPP should only be available for "Outcome for the subject HQ "
	const outcomesWithOutNdpp = allOutcomes.filter(
		({ outcome }) => outcome !== ndpp
	)
	const isGarecOrFocalPoint = user.garec || user.behaviourFocalPoint
	const findEndOfContract = outcomesWithOutNdpp.find(
		({ outcome }) => outcome === endOfContract
	)

	const filteredOutcomes = filterOutcomeSelections({
		allOutcomes: outcomesWithOutNdpp,
		selectedOutcomesArray,
	})
	//If user is Garec or Focal point we return all options without filtering
	if (isGarecOrFocalPoint) {
		return allOutcomes
	} else if (
		hrStatus === beneficiary ||
		hrStatus === careTakers ||
		hrStatus === partners ||
		hrStatus === communityMember ||
		hrStatus === dependent
	) {
		return filteredOutcomes.filter(({ outcome }) =>
			beneficiaryAndCommunityMembersOutcomes.includes(outcome)
		)
	} else if (hrStatus === dailyWorker) {
		return filteredOutcomes.filter(({ outcome }) =>
			dailyWorkersOutcomes.includes(outcome)
		)
	} else if (hrStatus === incentiveStaff || hrStatus === volunteers) {
		return filteredOutcomes.filter(({ outcome }) =>
			incentiveStaffOrVolunteersOutcomes.includes(outcome)
		)
	} else if (hrStatus === internationalStaff || hrStatus === hqStaff) {
		//If the logged in user is Gareg member or HrFocalPoint we need to include extra options 'internationalAndHqStaffGarecAndHrOptions'
		// const outcomesToBeFiltered = isGarecOrFocalPoint
		// 	? [
		// 			...internationalMobileAndHqAndBoardMemberStaffOutcomes,
		// 			...internationalAndHqStaffGarecAndHrOptions,
		// 	  ]
		// 	: internationalMobileAndHqAndBoardMemberStaffOutcomes

		const outcomesToBeFiltered =
			internationalMobileAndHqAndBoardMemberStaffOutcomes

		return filteredOutcomes.filter(({ outcome }) =>
			outcomesToBeFiltered.includes(outcome)
		)
	} else if (hrStatus === boardMember) {
		// const filteredOutComes = isGarecOrFocalPoint
		// 	? [
		// 			...internationalMobileAndHqAndBoardMemberStaffOutcomes,
		// 			...boardMemberGarecAndHrOptions,
		// 	  ]
		// 	: internationalMobileAndHqAndBoardMemberStaffOutcomes

		const filteredOutComes = internationalMobileAndHqAndBoardMemberStaffOutcomes
		return filteredOutcomes.filter(({ outcome }) =>
			filteredOutComes.includes(outcome)
		)
	} else if (hrStatus === nationalStaff) {
		//If endOfContract option is selected, we add two more options
		//End of collaboration && NDPP
		const isEndOfContractSelected =
			selectedOutcomesArray &&
			selectedOutcomesArray.includes(findEndOfContract.id)
		if (isEndOfContractSelected) {
			return filteredOutcomes.filter(({ outcome }) =>
				[
					...locallyHiredStaffOutcomes,
					endOfCollaborationWithOcb,
					//ndpp,
				].includes(outcome)
			)
		} else {
			return filteredOutcomes.filter(({ outcome }) =>
				locallyHiredStaffOutcomes.includes(outcome)
			)
		}
	} else {
		return outcomesWithOutNdpp
	}
}

export default filterOutcomesWithHrStatus

import { CircularProgress } from '@mui/material'
import useStyles from './useStyles'

const Progress = () => {
	const { MuiCircularProgress } = useStyles()
	return (
		<CircularProgress
			size={120}
			color="secondary"
			className={MuiCircularProgress}
		/>
	)
}

export default Progress

import React, { useEffect, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useCurrentUser } from '../../CurrentUserContext'
import { useTranslation } from 'react-i18next'
import { loader } from 'graphql.macro'
import { FormControl, MenuItem, InputLabel, Select } from '@mui/material'
import ReportTable from '../../UI/ReportTable'
import Header from '../../UI/Header'
import Progress from '../../UI/Progress'
import { isCoordoUser, sortAlphabetically } from '../ReportForm/functions'

const REPORTS_BY_PROJECT = loader('./graphql/reportsByProject.graphql')
const MISSIONS = loader('../ReportForm/graphql/missions.graphql')
const ProjectReports = () => {
	const currentUser = useCurrentUser()
	const { t } = useTranslation()
	const isCoordo = currentUser && isCoordoUser({ currentUser })
	const [missionCode, setMissionCode] = useState(() => {
		return window.localStorage.getItem('missionCodeForProject')
	})
	const [projectCode, setProjectCode] = useState(() => {
		return window.localStorage.getItem('projectCode')
	})
	const { data: { missions = null } = {}, loading: missionLoading } =
		useQuery(MISSIONS)

	const [
		fetchReportsByProjectCode,
		{ data: { reportsByProject = null } = {}, loading },
	] = useLazyQuery(REPORTS_BY_PROJECT)

	useEffect(() => {
		if (projectCode) {
			window.localStorage.setItem('projectCode', projectCode)
			fetchReportsByProjectCode({
				variables: {
					projectCode,
				},
			})
		}
	}, [projectCode])

	useEffect(() => {
		if (currentUser && currentUser.project) {
			window.localStorage.setItem(
				'missionCodeForProject',
				currentUser.project.mission.code
			)
			setMissionCode(currentUser.project.mission.code)
			window.localStorage.setItem('projectCode', projectCode)
			setProjectCode(currentUser.project.code)
		}
	}, [currentUser])

	return (
		<>
			<Header />
			{missionLoading ? (
				<Progress />
			) : (
				<>
					<FormControl
						style={{ minWidth: 220, marginBottom: '20px', marginRight: '20px' }}
						disabled={Boolean(currentUser.project)}
					>
						<InputLabel id="demo-simple-select-label">
							{t('select_mission')}
						</InputLabel>
						<Select
							value={missionCode}
							name="reportMission"
							label={t('select_mission')}
							onChange={({ target }) => {
								setMissionCode(target.value)
								window.localStorage.setItem(
									'missionCodeForProject',
									target.value
								)
							}}
						>
							{missions &&
								sortAlphabetically({ arr: missions, sortOn: 'code' }).map(
									({ id, code, name }) => (
										<MenuItem
											value={code}
											key={id}
										>{`${code} - ${name}`}</MenuItem>
									)
								)}
						</Select>
					</FormControl>
					{missionCode && (
						<FormControl
							style={{ minWidth: 220, marginBottom: '20px' }}
							disabled={currentUser.project && !isCoordo}
						>
							<InputLabel id="demo-simple-select-label">
								{t('select_project')}
							</InputLabel>
							<Select
								value={projectCode}
								name="reportMission"
								label={t('select_mission')}
								onChange={({ target }) => setProjectCode(target.value)}
							>
								{sortAlphabetically({
									arr: missions.find(({ code }) => code === missionCode)
										?.projects,
									sortOn: 'code',
								}).map(({ id, code, name }) => (
									<MenuItem
										value={code}
										key={id}
									>{`${code} - ${name}`}</MenuItem>
								))}
							</Select>
						</FormControl>
					)}

					{loading ? (
						<Progress />
					) : (
						reportsByProject && <ReportTable reports={reportsByProject} />
					)}
				</>
			)}
		</>
	)
}

export default ProjectReports

import React, { useContext, useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useCurrentUser } from '../../CurrentUserContext'
import { loader } from 'graphql.macro'
import { AppContext } from '../ReportForm/Context'
import Header from '../../UI/Header'
import Progress from '../../UI/Progress'
import GeneralInformation from '../ReportForm/GeneralInformation'
import Complainant from '../ReportForm/Complainant'
import AddSubject from '../ReportForm/AddSubject'
import AffectedPerson from '../ReportForm/AffectedPerson'
import Closure from '../ReportForm/Closure'
import Conclusion from '../ReportForm/Conclusion'
import Subject from '../ReportForm/Subject'

// import Confirm from './Confirm'
// import './styles.css'

const REPORT_BY_ID = loader('./graphql/reportById.graphql')

const handleSteps = (step, report) => {
	switch (step) {
		case 0:
			return <GeneralInformation report={report} />
		case 1:
			return <Complainant report={report} />
		case 2:
			return <AffectedPerson report={report} />
		case 3:
			return <Subject report={report} />
		case 4:
			return <Conclusion report={report} />
		case 5:
			return <AddSubject report={report} />
		case 6:
			return <Closure report={report} />
		default:
			throw new Error('Unknown step')
	}
}

const EditReportForm = () => {
	const currentUser = useCurrentUser()
	const { activeStep } = useContext(AppContext)
	const params = useParams()

	const { data: { reportById = null } = {}, loading } = useQuery(REPORT_BY_ID, {
		variables: {
			id: params.id,
		},
	})

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [activeStep])

	if (!currentUser) return <Navigate to="/" />

	return (
		<>
			<Header />
			{loading ? (
				<Progress />
			) : (
				reportById && (
					<div className="formContainer">
						{handleSteps(activeStep, reportById)}
					</div>
				)
			)}
		</>
	)
}

export default EditReportForm

import React, { useEffect, useState, useContext } from 'react'
import { AppContext } from '../Context'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import { useCurrentUser } from '../../../CurrentUserContext'
import {
	Box,
	OutlinedInput,
	MenuItem,
	FormControl,
	Select,
	Chip,
	Checkbox,
	FormLabel,
	FormHelperText,
	ListItemText,
} from '@mui/material/'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
}

function getStyles(name, selectedValue, theme) {
	return {
		fontWeight:
			selectedValue.indexOf(name) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium,
	}
}

const MultiSelect = ({
	array, //Array
	objKey, //String
	initialValue, //Array
	name, //String
	label, //String
	required, //Boolean
}) => {
	const { t } = useTranslation()
	const currentUser = useCurrentUser()
	const theme = useTheme()
	const [selectedItem, setSelectedItem] = useState([])
	const [disable, setDisable] = useState(false)
	const clearedOption = array.find(({ outcome }) => outcome === 'Cleared')
	//const ndppOption = array.find(({ outcome }) => outcome === 'NDPP')
	const noneOption = array.find(
		({ case: ipoTypology }) => ipoTypology === 'None'
	)
	//If user is a garec or focalpoint
	const isGarecOrFocalPoint =
		currentUser && (currentUser.garec || currentUser.behaviourFocalPoint)
	const { handleChange } = useContext(AppContext)
	const handleSelect = (event) => {
		const {
			target: { value },
		} = event

		//We Only disable other options if the user is not a garec or focalpoint
		if (!isGarecOrFocalPoint && (clearedOption || noneOption)) {
			if (clearedOption) {
				// If user selects 'Cleared', the other selected options should be removed and disabled
				const clearedOptionSelected = value.find(
					(id) => id === clearedOption.id
				)
				if (clearedOptionSelected) {
					setDisable(true)
					handleChange({ target: { value: [clearedOption.id], name } })
				} else {
					setDisable(false)
					handleChange(event)
				}
				//ndppOption and clearedOption can be in one list, we are making sure
				//both can not be selected together
				if (!clearedOptionSelected) {
					//If user selects 'NDPP', the other selected options should be removed and disabled
					//const ndppOptionSelected = value.find((id) => id === ndppOption.id)
					// if (ndppOptionSelected) {
					// 	setDisable(true)
					// 	handleChange({ target: { value: [ndppOption.id], name } })
					// } else {
					setDisable(false)
					handleChange(event)
					//}
				}
			}

			if (noneOption) {
				// If user selects 'none', the other selected options should be removed and disabled
				const noneOptionSelected = value.find((id) => id === noneOption.id)
				if (noneOptionSelected) {
					setDisable(true)
					handleChange({ target: { value: [noneOption.id], name } })
				} else {
					setDisable(false)
					handleChange(event)
				}
			}
		} else {
			setDisable(false)
			handleChange(event)
		}

		setSelectedItem(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value
		)
	}

	/**
	 * If initialValue.length is true, it means user is in edit-report
	 * so initialValue.length  must be true, in this case we setSelectedItem(initialValue)
	 * to have initialValue a default value in the Select form
	 */
	useEffect(() => {
		if (initialValue.length) {
			setSelectedItem(initialValue)
		}
	}, [initialValue])

	return (
		<div>
			<FormControl sx={{ m: 1, width: 300 }} required={required}>
				<FormLabel id="demo-radio-buttons-group-label">{t(label)}</FormLabel>
				<Select
					labelId="demo-multiple-chip-label"
					id={name}
					multiple
					value={selectedItem}
					name={name}
					onChange={handleSelect}
					input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
					renderValue={(selected) => (
						<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
							{selected.map((value) => (
								<Chip
									key={value}
									label={t(
										array.find(({ id }) => id === value)
											? array.find(({ id }) => id === value)[objKey]
											: ''
									)}
								/>
							))}
						</Box>
					)}
					MenuProps={MenuProps}
				>
					{array.map((element) => (
						<MenuItem
							disabled={Boolean(
								disable && initialValue.find((id) => id !== element.id)
							)}
							key={element.id}
							value={element.id}
							style={getStyles(element[objKey], selectedItem, theme)}
						>
							<Checkbox
								checked={Boolean(
									selectedItem?.find((id) => id === element?.id)
								)}
							/>
							<ListItemText primary={t(element[objKey])} />
						</MenuItem>
					))}
				</Select>
				<FormHelperText>{t('multi_select')}</FormHelperText>
			</FormControl>
		</div>
	)
}

export default MultiSelect

import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { Container } from '@mui/material'
import useStyles from './useStyles'
import Header from '../../UI/Header'
import homePageImage from '../../../images/homePageImage-1.png'

import './styles.css'

const Home = () => {
	const { t } = useTranslation()
	const { MuiContainer } = useStyles()

	useEffect(() => {
		window.scrollTo(0, 0)
	})

	return (
		<>
			<Header />
			<>
				<div style={{ padding: '10px 0 0' }}>
					<img src={homePageImage} alt="msf-logo" style={{ width: '10%' }} />
					<p className="image-owner">
						Illustrations: Simon Otten (Studio Monk)
					</p>
				</div>
				<Container maxWidth="md" className={MuiContainer}>
					<h3>{t('introduction')}</h3>
					<p>
						<Trans t={t}>
							{t('intro_text')}{' '}
							{<Link to="/guidelines">{t('the_guidelines')}</Link>}{' '}
							{t('before_encoding')}
						</Trans>
					</p>
				</Container>
			</>
		</>
	)
}

export default Home

import React, { useState, Fragment } from 'react'
import { useCurrentUser } from '../../CurrentUserContext'
import { useTranslation } from 'react-i18next'
import {
	AppBar,
	Button,
	Toolbar,
	IconButton,
	Box,
	SwipeableDrawer,
} from '@mui/material'
import { Menu as MenuIcon, AccountCircle } from '@mui/icons-material'
import { Link as RouterLink } from 'react-router-dom'
import LoginButton from '../LoginButton'
import Drawer from '../Drawer'
import ReportMenuOptions from '../ReportMenuOptions'
import LanguageOption from '../LanguageOption'
import msfLogo from '../../../images/msflogo-trans-background.png'
import useStyles from './useStyles'

const Header = ({ hideButtons }) => {
	const { t } = useTranslation()
	const currentUser = useCurrentUser()
	const [open, setOpen] = useState(false)
	const anchor = 'left'
	const {
		accountCircleIcon,
		accountCircleIconLink,
		header,
		logo,
		logoText,
		menuButton,
		menuBottonsContainer,
		toolbar,
		loggedInHeader,
		styledLink,
	} = useStyles

	const toggleDrawer = (open) => (event) => {
		if (
			event &&
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return
		}

		setOpen(open)
	}

	return (
		<>
			<Box sx={{ flexGrow: 1 }}>
				<AppBar style={currentUser ? { ...header, ...loggedInHeader } : header}>
					<Toolbar style={toolbar}>
						{/* If current user is true and hideButtons is false we show the burger menu icon*/}
						{currentUser && !hideButtons && (
							<IconButton
								color="inherit"
								aria-label="open drawer"
								edge="start"
								onClick={toggleDrawer(true)}
								style={menuButton}
							>
								<MenuIcon />
							</IconButton>
						)}
						<a href="/">
							<img src={msfLogo} alt="msfLogo" style={logo} />
						</a>
						<p style={logoText}>Behaviours case report</p>
						{/* If hideButtons is true, then the buttons will not be displayed*/}
						{hideButtons ? (
							<div style={menuBottonsContainer}></div>
						) : (
							<div style={menuBottonsContainer}>
								{currentUser && (
									<>
										{' '}
										<Button>
											<RouterLink to="/home" style={styledLink}>
												{t('home')}
											</RouterLink>
										</Button>
										<ReportMenuOptions />
										<Button>
											<a
												href="https://msfintl.sharepoint.com/sites/ocb-intranet/SitePages/About-MSF/Abusive-Inappropriate-Behaviour.aspx"
												target="_blank"
												rel="noreferrer"
												style={styledLink}
											>
												{t('Intranet')}
											</a>
										</Button>
										<Button>
											<RouterLink to="/guidelines" style={styledLink}>
												{t('guidelines')}
											</RouterLink>
										</Button>
										<Button>
											<RouterLink to="/support" style={styledLink}>
												{t('support')}
											</RouterLink>
										</Button>
									</>
								)}
							</div>
						)}

						<LanguageOption />
						{currentUser && (
							<RouterLink to="/profile" style={accountCircleIconLink}>
								<AccountCircle style={accountCircleIcon} />
							</RouterLink>
						)}
						<LoginButton />
					</Toolbar>
				</AppBar>
			</Box>
			{currentUser && (
				<Fragment>
					<SwipeableDrawer
						anchor={anchor}
						open={open}
						onClose={toggleDrawer(false)}
						onOpen={toggleDrawer(true)}
					>
						<Drawer open={open} />
					</SwipeableDrawer>
				</Fragment>
			)}
		</>
	)
}

export default Header

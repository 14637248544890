import React, { useEffect, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppContext } from './Context'
import BackButton from './ReusableInputs/BackButton'
import NextButton from './ReusableInputs/NextButton'
import {
	Box,
	FormControl,
	FormLabel,
	Grid,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material'

const AddSubject = ({ report }) => {
	const language = window.localStorage.i18nextLng
	const { t } = useTranslation()
	const [addSubject, setAddSubject] = useState(false)
	const { formValues, yesAndNoRadioOptions, numberOfSubjects, margin } =
		useContext(AppContext)

	useEffect(() => {
		if (report) {
			const { subjects } = report
			if (subjects.length > numberOfSubjects) {
				setAddSubject(true)
			}
		} else {
			setAddSubject(false)
		}
	}, [report])

	return (
		<>
			<h2>
				{language === 'ar'
					? `${t('add')} ${t('subject_of_complaint')} ${t('a')}${t(
							numberOfSubjects + 1
					  )}؟`
					: `${t('add')} ${t('a')} ${t(numberOfSubjects + 1)} ${t(
							'subject_of_complaint'
					  )}?`}
			</h2>
			<Grid
				container
				spacing={2}
				direction="column"
				justifyContent="center"
				alignItems="left"
				columns={16}
			>
				<Grid item xs={12}>
					<FormControl>
						{/* <FormLabel id="demo-radio-buttons-group-label">
							{t('another_subject')}
						</FormLabel> */}
						<RadioGroup
							id="anotherSubject"
							margin={margin}
							value={addSubject}
							name="anotherSubject"
							onChange={({ target: { value } }) =>
								setAddSubject(value === 'true' ? true : false)
							}
						>
							{yesAndNoRadioOptions.map(({ label, value }, i) => (
								<FormControlLabel
									key={i}
									value={value}
									control={<Radio />}
									label={t(label)}
								/>
							))}
						</RadioGroup>
					</FormControl>
				</Grid>
			</Grid>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<BackButton
					number={
						formValues[`subject_Conclusion_${numberOfSubjects}`]?.value ? 1 : 2
					}
				/>
				<NextButton number={addSubject ? -2 : 1} addSubject={addSubject} />
			</Box>
		</>
	)
}

export default AddSubject

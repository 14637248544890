import {
	oralWarning,
	writtenWarning,
	suspension,
	demotion,
	endOfContract,
	// endOfCollaborationWithOcb,
	// ndpp,
	earlyReturn,
	oralWarningOptions,
	writtenWarningAndEarlyReturnOptions,
	suspensionOptions,
	demotionOptions,
	endOfContractOptions,
} from '../../../../defaultValues'

const filterOutcomeSelections = ({ allOutcomes, selectedOutcomesArray }) => {
	const findOralWarning = allOutcomes.find(
		({ outcome }) => outcome === oralWarning
	)
	const findWrittenWarning = allOutcomes.find(
		({ outcome }) => outcome === writtenWarning
	)
	const findEarlyReturn = allOutcomes.find(
		({ outcome }) => outcome === earlyReturn
	)
	const findSuspension = allOutcomes.find(
		({ outcome }) => outcome === suspension
	)
	const findDemotion = allOutcomes.find(({ outcome }) => outcome === demotion)
	const findEndOfContract = allOutcomes.find(
		({ outcome }) => outcome === endOfContract
	)
	// const findEndOfCollaborationWithOcb = allOutcomes.find(
	// 	({ outcome }) => outcome === endOfCollaborationWithOcb
	// )
	// const findNdpp = allOutcomes.find(({ outcome }) => outcome === ndpp)

	if (selectedOutcomesArray) {
		if (selectedOutcomesArray.includes(findOralWarning.id)) {
			return allOutcomes.filter(({ outcome }) =>
				oralWarningOptions.includes(outcome)
			)
		} else if (
			selectedOutcomesArray.includes(findWrittenWarning.id) ||
			selectedOutcomesArray.includes(findEarlyReturn.id)
		) {
			return allOutcomes.filter(({ outcome }) =>
				writtenWarningAndEarlyReturnOptions.includes(outcome)
			)
		} else if (selectedOutcomesArray.includes(findSuspension.id)) {
			return allOutcomes.filter(({ outcome }) =>
				suspensionOptions.includes(outcome)
			)
		}
		// else if (selectedOutcomesArray.includes(findDemotion.id)) {
		// 	return allOutcomes.filter(({ outcome }) =>
		// 		demotionOptions.includes(outcome)
		// 	)
		// }
		else if (selectedOutcomesArray.includes(findEndOfContract.id)) {
			//Add endOfCollaborationToOcb & ndpp to allOutcomes
			//In case user selected findEndOfContract
			return allOutcomes.filter(({ outcome }) =>
				endOfContractOptions.includes(outcome)
			)
		} else {
			return allOutcomes
		}
	} else {
		return allOutcomes
	}
}

export default filterOutcomeSelections

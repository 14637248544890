import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useCurrentUser } from '../../CurrentUserContext'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import {
	isCellUser,
	isCoordoUser,
	isFieldUser,
} from '../../pages/ReportForm/functions'
import Header from '../../UI/Header'
import DeleteModal from '../../UI/DeleteModal'
import DialogComponent from '../../UI/DialogComponent'
import './styles.css'

const ReportTable = ({ reports, myReports }) => {
	const currentUser = useCurrentUser()
	const { t } = useTranslation()
	const [openModal, setOpenModal] = useState(false)
	const [reportId, setReportId] = useState()
	const toggleShow = (id) => {
		setOpenModal(!openModal)
		setReportId(id)
	}

	const cellUser = currentUser && isCellUser({ currentUser })
	const coordoUser = currentUser && isCoordoUser({ currentUser })
	const fieldUser = currentUser && isFieldUser({ currentUser })

	/**
	 * We have to hide the modify button after 30 days of the submission date
	 * for the field, coordo & cell users
	 */
	const today = new Date()

	const columns = [
		{ field: 'createdAt', headerName: t('created_at'), flex: 0.5, hide: true },
		{ field: 'reportDate', headerName: t('report_date'), flex: 0.5 },
		{
			field: 'project',
			headerName: t('Project'),
			valueGetter: ({ value }) => `${value?.name}`,
			flex: 1,
		},
		{
			field: 'subjectIpob',
			headerName: t('ibob_case'),
			renderCell: ({ row }) => {
				if (row.subjects.length > 2) {
					//If
					return <DialogComponent elements={row.subjects} conclusion={true} />
				} else {
					return (
						<div className="subject_container">
							{row.subjects.map((s, i) => {
								return (
									<p className="ipob_p" key={i}>
										{t('subject')} {i + 1}:
										{s.ipobTypologies.map((i) => i.case).join(', ')}
									</p>
								)
							})}
						</div>
					)
				}
			},

			flex: 1.5,
		},
		{
			field: 'conclusionIpob',
			headerName: t('conclusions_per_Ipob'),
			renderCell: ({ row }) => {
				console.log(row)
				if (row.subjects.length > 2) {
					return <DialogComponent elements={row.subjects} conclusion />
				} else {
					return (
						<div className="subject_container">
							{row.subjects.map((s, i) => {
								return (
									<p className="ipob_p" key={i}>
										{t('subject')} {i + 1}:
										{s.conclusion
											? s.conclusion.ipobTypologies
													.map((i) => i.case)
													.join(', ')
											: '-'}
									</p>
								)
							})}
						</div>
					)
				}
			},

			flex: 1.5,
		},
		{ field: 'caseClosedDate', headerName: t('closing_date'), flex: 1 },
		{
			field: t('modify'),
			flex: 0.3,
			//Show Modify column, only if the user is the owner of this report
			// or currentUser is a garec mamber or behaviourFocalPoint
			hide: !(
				(currentUser &&
					(currentUser.garec ||
						currentUser.garec ||
						currentUser.behaviourFocalPoint)) ||
				myReports
			),
			renderCell: ({ row }) => {
				const diffBetweenDates =
					today.getTime() - new Date(Number(row.createdAt)).getTime()
				const diffNumberOfDays = Math.ceil(
					diffBetweenDates / (1000 * 60 * 60 * 24)
				)
				if (diffNumberOfDays > 30 && (cellUser || coordoUser || fieldUser)) {
					return <span>-</span>
				} else {
					return (
						<Button
							key={row.id}
							variant="contained"
							color="primary"
							component={Link}
							to={`/edit-report/${row.id}`}
						>
							{t('modify')}
						</Button>
					)
				}
			},
		},

		{
			field: t('view'),
			flex: 0.3,
			renderCell: ({ row }) => {
				return (
					<Button
						variant="contained"
						color="primary"
						component={Link}
						to={`/report/${row.id}`}
					>
						{t('view')}
					</Button>
				)
			},
		},

		{
			field: t('delete'),
			flex: 0.3,
			//Show delete column only if the user is Garec or behaviourFocalPoint
			hide: !(
				currentUser &&
				(currentUser.garec || currentUser.behaviourFocalPoint)
			),
			renderCell: ({ row }) => {
				return (
					<Button
						key={row.id}
						variant="contained"
						style={{ backgroundColor: '#FD0000' }}
						onClick={() => toggleShow(row.id)}
					>
						{t('delete')}
					</Button>
				)
			},
		},
	]

	return (
		<>
			<Header />
			<DeleteModal
				show={openModal}
				toggleShow={toggleShow}
				reportId={reportId}
			/>
			{currentUser && reports && (
				<div style={{ height: '100%', width: '100%' }}>
					<DataGrid
						columns={columns}
						rows={reports}
						components={{ Toolbar: GridToolbar }}
						componentsProps={{
							toolbar: {
								//Disable export csv button for non garec and behaviourFocalPoint users
								csvOptions: {
									disableToolbarButton:
										!currentUser.garec && !currentUser.behaviourFocalPoint,
								},
							},
						}}
						initialState={{
							sorting: {
								sortModel: [{ field: 'createdAt', sort: 'desc' }],
							},
						}}
						autoHeight={true}
					/>
				</div>
			)}
		</>
	)
}

export default ReportTable

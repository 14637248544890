import React, { useEffect, useCallback, useContext, useState } from 'react'
import { AppContext } from './Context'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'
import { useQuery, useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import BackButton from './ReusableInputs/BackButton'
import NextButton from './ReusableInputs/NextButton'
import {
	Box,
	FormControl,
	FormLabel,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Grid,
	TextField,
} from '@mui/material'
import RadioGroupInput from './ReusableInputs/RadioGroupInput'
import MultiSelect from './ReusableInputs/MultiSelect'
import { conclusionInitialValues } from '../EditReportForm/initialValues'
import { isDefaultOutcome, filterOutcomesWithHrStatus } from './functions'

import './styles.css'

const ALL_OUTCOMES = loader('./graphql/allOutcomes.graphql')

const IPOB_LIST = loader('./graphql/allIpobTypologies.graphql')
const HR_STATUS = loader('./graphql/hrStatus.graphql')

const Conclusion = ({ report }) => {
	const { t } = useTranslation()
	const currentUser = useCurrentUser()
	const [checked, setChecked] = useState(false)
	const [filteredOutcomes, setFilteredOutcomes] = useState([])
	// const currentUser = useCurrentUser()
	const {
		formValues,
		handleChange,
		allIpobTypologiesExternalLink,
		yesAndNoRadioOptions,
		numberOfSubjects,
		variant,
		margin,
	} = useContext(AppContext)

	const {
		[`conclusion_PerIpob_${numberOfSubjects}`]: conclusion_PerIpob,
		[`conclusion_SubjectOutcome_${numberOfSubjects}`]:
			conclusion_SubjectOutcome,
		[`conclusion_SubjectOutcomeHq_${numberOfSubjects}`]:
			conclusion_SubjectOutcome_hq,
		[`conclusion_otherSubjectOutcome_${numberOfSubjects}`]:
			conclusion_otherSubjectOutcome,
		[`conclusion_createOtherSubjectOutcome_${numberOfSubjects}`]:
			conclusion_createOtherSubjectOutcome,
		[`conclusion_SubjectLegalIssues_${numberOfSubjects}`]:
			conclusion_SubjectLegalIssues,
		[`conclusion_AuthoritiesInvolvement_${numberOfSubjects}`]:
			conclusion_AuthoritiesInvolvement,
		[`subject_HrStatus_${numberOfSubjects}`]: subject_HrStatus,
	} = formValues

	const { data: { allOutcomes = null } = {} } = useQuery(ALL_OUTCOMES)

	const { data: { allIpobTypologies = null } = {} } = useQuery(IPOB_LIST)

	const [getHrStatus, { data: { hrStatus = null } = {} }] =
		useLazyQuery(HR_STATUS)

	// Check if all values are not empty and if there are some errors
	const isError = useCallback(
		() =>
			Object.keys({
				conclusion_PerIpob,
				conclusion_SubjectOutcome,
				conclusion_SubjectOutcome_hq,
				conclusion_SubjectLegalIssues,
				conclusion_AuthoritiesInvolvement,
			}).some(
				() =>
					!(
						conclusion_PerIpob &&
						conclusion_PerIpob.value?.length &&
						conclusion_SubjectOutcome &&
						(conclusion_SubjectOutcome?.value?.length ||
							conclusion_createOtherSubjectOutcome?.value !== '')
					)
			),
		[
			formValues,
			conclusion_PerIpob,
			conclusion_SubjectOutcome,
			conclusion_SubjectLegalIssues,
			conclusion_AuthoritiesInvolvement,
		]
	)
	useEffect(() => {
		//If user seletced 'other' it means the HrStatus is not created in the DB yet
		//So we do not have to query it
		if (subject_HrStatus.value && subject_HrStatus.value !== 'other') {
			getHrStatus({
				variables: {
					id: subject_HrStatus.value,
				},
			})
		}
	}, [subject_HrStatus.value])

	useEffect(() => {
		//If we're in create mode so report is false
		//We clear up conclusion_SubjectOutcome array
		//Because the options will also change and some options will be taken off
		//That will cause empty selections wll effect the UX
		if (!report) {
			handleChange({
				target: {
					name: `conclusion_SubjectOutcome_${numberOfSubjects}`,
					value: [],
				},
			})
		}
	}, [hrStatus])

	useEffect(() => {
		if (allOutcomes) {
			const outcomes = filterOutcomesWithHrStatus({
				allOutcomes,
				hrStatus: hrStatus?.status,
				selectedOutcomesArray: conclusion_SubjectOutcome?.value,
				user: currentUser,
			})
			//In case there is undefined values we clear it up
			setFilteredOutcomes(outcomes.filter(Boolean))
		}
	}, [allOutcomes, hrStatus, conclusion_SubjectOutcome])

	useEffect(() => {
		if (report) {
			conclusionInitialValues.map(({ name, mainKey, subKey }) => {
				const { subjects } = report
				const currentSubject = subjects[numberOfSubjects - 1]
				//If there is a subject already we check if there's a cnclusion attached to it
				//if not we return null
				const currentConclusion = currentSubject
					? currentSubject.conclusion
					: null

				if (currentConclusion) {
					if (subKey === `array`) {
						handleChange({
							target: {
								name: `${name}_${numberOfSubjects}`,
								value: currentConclusion[mainKey].map(({ id }) => id),
							},
						})
					} else if (subKey === `string`) {
						handleChange({
							target: {
								name: `${name}_${numberOfSubjects}`,
								value:
									currentConclusion[mainKey] === 'true'
										? true
										: currentConclusion[mainKey] === 'false'
										? false
										: currentConclusion[mainKey],
							},
						})
					} else if (subKey) {
						handleChange({
							target: {
								name: `${name}_${numberOfSubjects}`,
								value: currentConclusion[mainKey]
									? currentConclusion[mainKey][subKey]
									: currentConclusion[mainKey],
							},
						})
					}
				}

				if (allOutcomes && currentConclusion?.outcomes?.length) {
					/**
					 * If the user has created an outcome and did not select from the list
					 * isDefaultOutcome() should return false, then we change the value of three
					 * variables conclusion_SubjectOutcome to []
					 * , conclusion_otherSubjectOutcome to true (the checkbox)
					 * conclusion_createOtherSubjectOutcome to the outcome text the user created before
					 *
					 */
					const findNotDefaultOutcome = isDefaultOutcome({
						outcomeId: currentConclusion.outcomes[0].id,
						defaultOutComeArr: allOutcomes,
					})

					if (!findNotDefaultOutcome) {
						handleChange({
							target: {
								name: `conclusion_otherSubjectOutcome_${numberOfSubjects}`,
								value: true,
							},
						})
						handleChange({
							target: {
								name: `conclusion_createOtherSubjectOutcome_${numberOfSubjects}`,
								value: currentConclusion.outcomes[0].outcome,
							},
						})
						handleChange({
							target: {
								name: `conclusion_SubjectOutcome_${numberOfSubjects}`,
								value: [],
							},
						})
						handleChange({
							target: {
								name: `conclusion_SubjectOutcomeHq_${numberOfSubjects}`,
								value: [],
							},
						})
					}
				}
			})
		}
	}, [report, allOutcomes])

	useEffect(() => {
		if (conclusion_otherSubjectOutcome?.value) {
			setChecked(true)
		} else {
			setChecked(false)
		}
	}, [conclusion_otherSubjectOutcome])

	return (
		<>
			<h2>{`${t('conclusions_subject')} #${numberOfSubjects}`}</h2>
			<Grid
				container
				spacing={2}
				direction="column"
				justifyContent="center"
				alignItems="left"
				columns={16}
			>
				<Grid item xs={12}>
					{allIpobTypologies && (
						<MultiSelect
							required={true}
							array={allIpobTypologies}
							objKey="case"
							initialValue={conclusion_PerIpob?.value || []}
							name={`conclusion_PerIpob_${numberOfSubjects}`}
							label="conclusions_per_Ipob"
						/>
					)}
				</Grid>
				<a
					className="hyper-link"
					href={allIpobTypologiesExternalLink()}
					target="_blank"
					rel="noreferrer"
				>
					{t('ipob_definition')}
				</a>
				<Grid item xs={12}>
					{!conclusion_otherSubjectOutcome?.value && filteredOutcomes && (
						<MultiSelect
							required={true}
							array={filteredOutcomes}
							objKey="outcome"
							initialValue={conclusion_SubjectOutcome?.value || []}
							name={`conclusion_SubjectOutcome_${numberOfSubjects}`}
							label="subject_outcome"
						/>
					)}
					{currentUser?.behaviourFocalPoint && allOutcomes && (
						<MultiSelect
							required={true}
							array={allOutcomes}
							objKey="outcome"
							initialValue={conclusion_SubjectOutcome_hq?.value || []}
							name={`conclusion_SubjectOutcomeHq_${numberOfSubjects}`}
							label="subject_outcome_hq"
						/>
					)}
					{!conclusion_SubjectOutcome?.value?.length && (
						<Grid item xs={12} sm={6}>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											onChange={handleChange}
											name={`conclusion_otherSubjectOutcome_${numberOfSubjects}`}
											checked={Boolean(checked)}
										/>
									}
									label={t('other_outcome')}
								/>
							</FormGroup>
						</Grid>
					)}
					{conclusion_otherSubjectOutcome?.value && (
						<Grid item xs={12} sm={6}>
							<TextField
								variant={variant}
								margin={margin}
								fullWidth
								label={t('other_outcome')}
								name={`conclusion_createOtherSubjectOutcome_${numberOfSubjects}`}
								placeholder={t('other_outcome')}
								value={conclusion_createOtherSubjectOutcome?.value}
								onChange={handleChange}
								required={conclusion_otherSubjectOutcome.value}
							/>
						</Grid>
					)}
				</Grid>
				<Grid item xs={12}>
					<FormControl>
						<FormLabel id="demo-radio-buttons-group-label">
							{t('subject_legal_issues')}
						</FormLabel>
						<RadioGroupInput
							id={`conclusion_SubjectLegalIssues${numberOfSubjects}`}
							name={`conclusion_SubjectLegalIssues_${numberOfSubjects}`}
							value={conclusion_SubjectLegalIssues?.value}
							array={[
								...yesAndNoRadioOptions,
								{ label: 'unknown', value: 'unknown' },
							]}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControl>
						<FormLabel id="demo-radio-buttons-group-label">
							{t('authorities_involvement')}
						</FormLabel>
						<RadioGroupInput
							id={`conclusion_AuthoritiesInvolvement${numberOfSubjects}`}
							name={`conclusion_AuthoritiesInvolvement_${numberOfSubjects}`}
							value={conclusion_AuthoritiesInvolvement?.value}
							array={[
								...yesAndNoRadioOptions,
								{ label: 'unknown', value: 'unknown' },
							]}
						/>
					</FormControl>
				</Grid>
			</Grid>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<BackButton number={1} />
				<NextButton error={isError()} number={1} />
			</Box>
		</>
	)
}

export default Conclusion

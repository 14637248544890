import React from 'react'
import { useTranslation } from 'react-i18next'
import {
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
} from '@mui/material/'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material/'
import ConclusionSummary from './ConclusionSummary'
import './styles.css'

const SubjectSummary = ({ report }) => {
	const { subjects } = report
	const { t } = useTranslation()

	const values = [
		{ text: t('subject_gender'), mainKey: 'gender', subKey: 'gender' },
		{
			text: t('subject_hrStatus'),
			mainKey: 'hrStatus',
			subKey: 'status',
		},
		{
			text: t('subject_field_position'),
			mainKey: 'fieldPosition',
			subKey: 'position',
		},
		{
			text: t('subject_hq_position'),
			mainKey: 'hqPosition',
			subKey: 'position',
		},
		{
			text: t('subject_contracting_office'),
			mainKey: 'contractingOffice',
			subKey: 'country',
		},
		{
			text: t('subject_msf_experience'),
			mainKey: 'experience',
			subKey: 'experience',
		},
		{
			text: t('subject_minor'),
			mainKey: 'isMinor',
			subKey: false,
		},
		{
			text: t('subject_nationality'),
			mainKey: 'nationality',
			subKey: 'country',
		},
		{
			text: t('ibob_case'),
			mainKey: 'ipobTypologies',
			subKey: 'case',
			anotherType: 'array',
		},
	]

	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<Typography className="typography">{t('subjects')}</Typography>
			</AccordionSummary>
			{subjects.map((sub, i) => {
				return (
					<Accordion key={i}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography>
								{t('subject_of_complaint')} #{i + 1}
							</Typography>
						</AccordionSummary>
						<AccordionDetails className="accordionDetails">
							{values.map(({ text, mainKey, subKey, anotherType }, i) => {
								const textToRender =
									anotherType !== 'array' && sub[mainKey] && subKey
										? t(sub[mainKey][subKey])
										: anotherType !== 'array' && t(sub[mainKey])

								const finalTextToRender =
									anotherType === 'array'
										? sub[mainKey].map((elm) => t(elm[subKey])).toString()
										: textToRender
								return (
									finalTextToRender && (
										<div key={i}>
											<Typography>{`- ${text}`}</Typography>
											<Typography className="typography-answer">{`${finalTextToRender}`}</Typography>
										</div>
									)
								)
							})}
						</AccordionDetails>
						{sub.conclusion && (
							<ConclusionSummary conclusion={sub.conclusion} />
						)}
					</Accordion>
				)
			})}
		</Accordion>
	)
}

export default SubjectSummary

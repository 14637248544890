import React, { useContext } from 'react'
import { AppContext } from '../Context'
import { useTranslation } from 'react-i18next'

import { Button } from '@mui/material'

const BackButton = ({ number, removeSubject }) => {
	const { t } = useTranslation()
	const { handleBack } = useContext(AppContext)
	return (
		<Button
			variant="contained"
			sx={{ mt: 3, ml: 1 }}
			style={{ backgroundColor: '#F9F9F9', color: '#666666' }}
			onClick={() =>
				handleBack({
					number,
					removeSubject,
				})
			}
		>
			{t('back')}
		</Button>
	)
}

export default BackButton

import React from 'react'
import { useTranslation } from 'react-i18next'
import {
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
} from '@mui/material/'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material/'
import './styles.css'

const ClosureSummary = ({ report }) => {
	const { t } = useTranslation()

	const values = [
		{
			text: t('closing_date'),
			mainKey: 'caseClosedDate',
			subKey: false,
		},
		{
			text: t('where_facts_found'),
			mainKey: 'whereFactsCanBeFound',
			subKey: 'to',
		},
		{
			text: t('comment'),
			mainKey: 'comment',
		},
	]

	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<Typography className="typography">{t('closure')}</Typography>
			</AccordionSummary>
			<AccordionDetails className="accordionDetails">
				{values.map(({ text, mainKey, subKey, anotherType }, i) => {
					const textToRender =
						anotherType !== 'array' && report[mainKey] && subKey
							? t(report[mainKey][subKey])
							: anotherType !== 'array' && t(report[mainKey])

					const finalTextToRender =
						anotherType === 'array'
							? report[mainKey].map((elm) => t(elm[subKey])).toString()
							: textToRender
					return (
						finalTextToRender && (
							<div key={i}>
								<Typography>{`- ${text}`}</Typography>
								<Typography className="typography-answer">{`${finalTextToRender}`}</Typography>
							</div>
						)
					)
				})}
			</AccordionDetails>
		</Accordion>
	)
}

export default ClosureSummary

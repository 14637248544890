export const TRANSLATIONS_EN = {
	sign_in: 'Sign in',
	sign_out: 'Sign out',
	GAREC: 'GAREC',
	Project: 'Project',
	Coordination: 'Coordination',
	'Cell or RST': 'Cell or RST',
	'HR department': 'HR department(based in HQ)',
	'Regional Support Team': 'Regional Support Team',
	Others: 'Others',
	'Complainant or affected person identified safety risks':
		'Complainant or affected person identified safety risks',
	'Complainant or affected person identified security risks':
		'Complainant or affected person identified security risks',
	'Complainant or affected person dropped the case for other or unknown reasons':
		'Complainant or affected person dropped the case for other or unknown reasons',
	'An external investigation is ongoing':
		'An external investigation is ongoing',
	'The investigation team dropped the case as the complainant was managed directly by the HR/Ops line':
		'The investigation team dropped the case as the complainant was managed directly by the HR/Ops line',
	'Investigation on the same incident has been already concluded':
		'Investigation on the same incident has already been concluded',
	Female: 'Female',
	Male: 'Male',
	'Non-binary': 'Non-binary',
	Transgender: 'Transgender',
	'Internationally Mobile Staff': 'Internationally Mobile Staff',
	'Locally Hired Staff': 'Locally Hired Staff',
	'HQ staff': 'HQ staff',
	'Daily worker': 'Daily worker',
	'Incentive staff': 'Incentive staff',
	Beneficiary: 'Beneficiary',
	'Care takers': 'Care takers',
	Partners: 'Partners',
	'Community member': 'Community member',
	'Board member or association member': 'Board member or Associative member',
	Dependent: 'Dependent',
	Coordinator: 'Coordinator',
	'Activity Manager': 'Activity Manager',
	Supervisor: 'Supervisor',
	'Skilled position': 'Skilled position',
	'Basic skilled position': 'Basic skilled position',
	Employee: 'Employee',
	'Manager or Middle manager': 'Manager or Middle manager',
	Director: 'Director',
	'Less than a year': 'Less than a year',
	'1 to 5 years': '1 to 5 years',
	'6 to 10 years': '6 to 10 years',
	'More than 10 years': 'More than 10 years',
	'Abuse of power': 'Abuse of power',
	Discrimination: 'Discrimination',
	'Sexual harassment': 'Sexual harassment',
	'Sexual exploitation': 'Sexual exploitation',
	'Inappropriate relationship': 'Inappropriate relationship',
	'Substance abuse': 'Substance abuse',
	'Harassment or Bullying': 'Harassment or Bullying',
	'Sexual abuse': 'Sexual abuse',
	Exploitation: 'Exploitation',
	Agression: 'Agression',
	Retaliation: 'Retaliation',
	'Interference in a case': 'Interference in a case',
	'False reporting': 'False reporting',
	'Breach of confidentiality': 'Breach of confidentiality',
	'Inappropriate communication': 'Inappropriate communication',
	'Inappropriate management': 'Inappropriate management',
	Cleared: 'Cleared',
	'Follow up': 'Follow up',
	'Note to file': 'Note to file',
	'Oral warning': 'Verbal warning',
	'Written warning': 'Written warning',
	Demotion: 'Demotion',
	Suspension: 'Suspension',
	'End of contract': 'End of contract',
	'End of collaboration': 'End of collaboration',
	NDPP: 'NDPP',
	'Early Return': 'Early Return',
	general_info: 'General information',
	report_date: 'Date the case was reported by complainant to MSF',
	date: 'Date',
	incident_mission: 'Mission where the incident happened',
	incident_project: 'Project where incident happened',
	report_to: 'Case reported to',
	investigation_undertaken: 'Investigation Undertaken',
	investigated_by: 'Investigated by',
	garec_advice:
		'Advice / Support requested from the GAREC or Support Officer for Ethics & Behaviour (HR Ops)?',
	why_no_invistigation: 'Why was there no investigation undertaken?',
	yes: 'Yes',
	no: 'No',
	complainant: 'Complainant',
	complainant_gender: 'Gender of the complainant',
	complainant_hrStatus: 'HR status of the complainant',
	other: 'Other',
	Other: 'Other',
	complainant_field_position: 'Position of complainant at Field Level',
	complainant_hq_position: 'Position of the complainant at HQ',
	complainant_contracting_office: 'Employing office or country of complainant',
	complainant_msf_experience: 'MSF Experience of complainant',
	complainant_minor:
		'Is the complainant a minor as defined in the Behavioral Commitments?',
	complainant_nationality: 'Nationality of complainant',
	is_complainant_effected_person: 'Is the complainant the affected person?',
	next: 'Next',
	back: 'Back',
	affected_person: 'Affected Person',
	affectedPerson_gender: 'Gender of the affected person',
	affectedPerson_hrStatus: 'HR status of the affected person',
	affectedPerson_field_position:
		'Position of the affected person at Field Level',
	affectedPerson_hq_position: 'Position of the affected person at HQ level',
	affectedPerson_contracting_office:
		'Employing office or country of the affected person',
	affectedPerson_msf_experience: 'MSF Experience of the affected person',
	affectedPerson_minor:
		'Is the affected person a minor as defined in the Behavioral Commitments?',
	affectedPerson_nationality: 'Nationality of the affected person',
	subject_of_complaint: 'Subject of Complaint',
	subject: 'Subject',
	subject_gender: 'Gender of the subject',
	subject_hrStatus: 'HR status of the subject',
	subject_field_position: 'Position of the subject in the Field',
	subject_hq_position: 'Position of the subject at HQ',
	subject_contracting_office: 'Employing office or country of the subject',
	subject_msf_experience: 'MSF Experience of the subject',
	subject_minor:
		'Is the subject a minor as defined in the Behavioral Commitments?',
	subject_nationality: 'Nationality of the subject',
	ibob_case: 'IPOB typologies described by the complainant for the subject',
	subject_conclusion: 'Was there a conclusion for subject',
	None: 'None',
	conclusions_per_Ipob: 'Conclusions as per IPOB Typology for the subject',
	subject_outcome: 'Outcome for the subject',
	subject_legal_issues: 'Legal/criminal/statutory issues involved for subject',
	authorities_involvement: 'Involvement of the authorities for subject',
	conclusions_subject: 'Conclusions for the subject',
	unknown: 'Unknown',
	another_subject: 'Was there a second subject of the complaint?',
	closure: 'Closure',
	closing_date: 'Date the case was closed',
	where_facts_found: 'Where the facts can be found?',
	submit: 'Submit',
	1: 'first',
	2: 'second',
	3: 'third',
	4: 'fourth',
	5: 'fifth',
	6: 'sixth',
	7: 'seventh',
	8: 'eighth',
	9: 'ninth',
	10: 'tenth',
	a: 'a',
	add: 'Add',
	menu: 'List',
	new_report: 'New Report',
	select_mission: 'Select a mission',
	select_cell: 'Select a cell',
	my_reports: 'My Reports',
	mission_reports: 'Mission Reports',
	cell_reports: 'Cell Reports',
	modify: 'Modify',
	true: 'Yes',
	false: 'No',
	subjects: 'Subjects',
	view: 'View',
	welcome_msg: 'Welcome to the Behavior Reporting System (BRS)',
	Mission: 'Mission',
	other_outcome: 'Other outcome',
	garec_reports: 'Garec Reports',
	delete: 'Delete',
	remove_msg:
		'You are about to delete a report, write a note before clicking on Delete',
	note: 'Note',
	success_remove_msg: 'This report has been removed successfully!',
	close: 'Close',
	all_reports: 'All Reports',
	about: 'About',
	about_text:
		'The BRS allows you to register cases of Inappropriate and Abusive Behavior. The system gathers anonymized information on individual cases, allowing us to get a better insight on our capacity to identify and manage behavior complaints in the field. Before encoding, please make sure you have read the guidelines below:',
	guidelines: 'Guidelines',
	the_guidelines: 'The Guidelines',
	contact_text:
		'If you have any doubt or need additional information, please don’t hesitate to contact us at: ',
	multi_select: 'You can select more than one value',
	subject_outcome_hq: 'Outcome for the subject HQ',
	comment: 'Comment',
	complainant_is: 'The complaint was made by?',
	'An identified person': 'An identified person',
	'An individual who sent anonymous alert':
		'An individual who sent anonymous alert',
	'A multiple anonymous alert': 'A multiple anonymous alert',
	affectedPerson_identified: 'The affected person has been identified?',
	introduction: 'Introduction',
	intro_text:
		'The purpose of this Behaviour Reporting System (BRS) is to alert and report cases to the field and head office coordination teams, but also to analyze and archive the cases in order to give us the capacity to develop adapted structural responses in terms of prevention and reaction for each of our fields.<br> HR profiles are asked to fill in this online database <strong>for every complaint of abusive and inappropriate behaviour.</strong> Should there be no investigation, encoding can be done directly. Should an investigation be launched, encoding will be done when it’s closed (final decision taken and communicated).<br>The BRS has a pyramidal access so that each HR Manager, HRCo and HRO has a global view of the cases of inappropriate and abusive behaviour on their project, mission or cell/RST. This aims at supporting the prevention strategy in all locations.<br>It is essential that you read',
	before_encoding:
		'before encoding to ensure that you have all the necessary information to do so.',
	ipob_definition: 'Click here to see the IPOB definition',
	home: 'Home',
	report: 'Report',
	Volunteers: 'Volunteers',
	comment_warning:
		'This section isn’t mandatory and should be used for specific needs only. To preserve Confidentiality in this Anonymized database, the section Comment can never include any detail on the case that would reveal the identity of the people involved.',
	how_to_get_support: 'How can you get support?',
	technical_support:
		'For all technical questions related to the BRS (access, bug, etc.), you can contact directly',
	support_mail_info:
		'with the following subject to your e-mail: BRS Support / Country / Project / Need',
	other_support: 'For any other question or support, you can contact your HRO',
	or: 'or',
	support: 'Support',
	guidelines_download_en: 'Click to download the guidelines in English',
	click_view_ipob: 'Click to view IPOB typologies',
	access_error:
		'You are redirected to this page because either you are not suppose to access this website or your profile is not configured correctly.',
	access_error_contact:
		'If you think you should be able to have access, please take screenshot and send it to the admin.',
	project_reports: 'Project Reports',
	select_project: 'Select a Project',
}

const useStyles = {
	styledButton: {
		color: '#333333',
		fontWeight: 'bold',
	},
	styledLink: {
		textdecoration: 'none',
		color: '#333333',
	},
}

export default useStyles

import React, { useEffect, useCallback, useContext } from 'react'
import axios from 'axios'
import { AppContext } from './Context'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {
	Button,
	Box,
	MenuItem,
	InputLabel,
	FormControl,
	Select,
	Grid,
	TextField,
} from '@mui/material'
import { closureInitialValues } from '../EditReportForm/initialValues'
// import TextInput from './ReusableInputs/TextInput'

const FACTS_CAN_BE_FOUND = loader('./graphql/factsCanBeFound.graphql')

const Closure = ({ report }) => {
	const { t } = useTranslation()
	const currentUser = useCurrentUser()
	const { formValues, handleChange, handleBack, numberOfSubjects } =
		useContext(AppContext)
	const {
		closure_ClosingDate,
		closure_WhereFactsFound,
		closure_Comment,
		//generalInfo_reportDate,
	} = formValues

	const { data: { factsCanBeFound = null } = {} } = useQuery(FACTS_CAN_BE_FOUND)

	// Check if all values are not empty and if there are some errors
	const isError = useCallback(
		() =>
			Object.keys({
				closure_ClosingDate,
				closure_WhereFactsFound,
				closure_Comment,
			}).some(
				(name) =>
					(formValues[name].required && !formValues[name].value) ||
					formValues[name].error
			),
		[formValues, closure_ClosingDate, closure_WhereFactsFound, closure_Comment]
	)

	const handleSubmit = (e) => {
		e.preventDefault()
		//If value report is true, send POST request to edit-form
		if (report) {
			axios
				.post('/edit-form', {
					...formValues,
					numberOfSubjects,
					userId: currentUser.id,
					report,
				})
				.then((response) => {
					return response.status === 200
						? (window.location.href = '/my-reports')
						: null
				})
				.catch((error) => {
					console.log(error)
				})
		} else {
			axios
				.post('/create-form', {
					...formValues,
					numberOfSubjects,
					userId: currentUser.id,
				})
				.then((response) => {
					return response.status === 200
						? (window.location.href = '/my-reports')
						: null
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}

	useEffect(() => {
		if (report) {
			closureInitialValues.map(({ name, mainKey, subKey }) => {
				if (subKey) {
					handleChange({
						target: {
							name,
							value: report[mainKey][subKey],
						},
					})
				} else {
					handleChange({
						target: {
							name: name,
							value: report[mainKey],
						},
					})
				}
			})
		}
	}, [report])

	return (
		<>
			<h2>{t('closure')}</h2>
			<Grid
				container
				spacing={2}
				direction="column"
				justifyContent="center"
				alignItems="left"
				columns={16}
			>
				<Grid item xs={16}>
					<section>{t('closing_date')}</section>
				</Grid>
				<Grid item xs={8} alignItems="left">
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							name="closure_ClosingDate"
							label={t('date')}
							disableFuture
							//Passing the value to the Context manually as it is not working with the onChange prop
							onChange={(value) =>
								handleChange({
									target: {
										type: 'date',
										name: 'closure_ClosingDate',
										value: dayjs(value.$d).format('YYYY-MM-DD'),
									},
								})
							}
							format="DD/MM/YYYY"
							required={closure_ClosingDate.required}
							value={dayjs(closure_ClosingDate.value)}
							minimumDate={dayjs(closure_ClosingDate.value)}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid item xs={12}>
					<FormControl fullWidth required={closure_WhereFactsFound.required}>
						<InputLabel>{t('where_facts_found')}</InputLabel>
						<Select
							id="whereFactsFoundId"
							value={closure_WhereFactsFound.value}
							name="closure_WhereFactsFound"
							label={t('where_facts_found')}
							onChange={handleChange}
						>
							{factsCanBeFound &&
								factsCanBeFound.map(({ to, id }) => (
									<MenuItem value={id} key={id}>
										{t(to)}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			<FormControl fullWidth sx={{ m: 1 }} variant="standard">
				<TextField
					//'column-reverse' to show the helperText above the input by default helperText is below the input
					style={{ flexDirection: 'column-reverse' }}
					helperText={t('comment_warning')}
					fullWidth
					id="comment"
					name="closure_Comment"
					placeholder={t('comment')}
					value={closure_Comment.value}
					multiline
					onChange={handleChange}
				/>
			</FormControl>

			<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
				<Button sx={{ mr: 1 }} onClick={() => handleBack({ number: 1 })}>
					{t('back')}
				</Button>
				<Button
					variant="contained"
					color="success"
					disabled={isError()}
					onClick={handleSubmit}
					style={{ backgroundColor: '#FD0000' }}
				>
					{t('submit')}
				</Button>
			</Box>
		</>
	)
}

export default Closure

import React, { useState } from 'react'
import { useCurrentUser } from '../../CurrentUserContext'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Collapse, Divider, List, ListItem, ListItemText } from '@mui/material'
import {
	Description,
	ExpandMore,
	ExpandLess,
	NoteAddOutlined,
	ListAlt,
	Home,
	Lightbulb,
	Info,
} from '@mui/icons-material'
import {
	isCellUser,
	isCoordoUser,
	isFieldUser,
} from '../../pages/ReportForm/functions'
import './styles.css'

const Drawer = () => {
	const [open, setOpen] = useState(true)
	const currentUser = useCurrentUser()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const cellUser = currentUser && isCellUser({ currentUser })
	const isCoordo = currentUser && isCoordoUser({ currentUser })
	const isField = currentUser && isFieldUser({ currentUser })

	const changeLocation = (placeToGo) => {
		navigate(placeToGo, { replace: true })
		window.location.reload()
	}

	const handleClick = () => {
		setOpen(!open)
	}

	const handleRedirect = (e) => {
		e.preventDefault()
		const url =
			'https://msfintl.sharepoint.com/sites/ocb-intranet/SitePages/About-MSF/Abusive-Inappropriate-Behaviour.aspx'
		window.open(url, '_blank')
	}

	return (
		<div style={{ width: 240 }}>
			<List></List>
			<Divider />
			<List>
				<ListItem button key={7} component={Link} to={'/home'}>
					<Home className="drawerIcon" />
					<ListItemText primary={t('home')} className="listItemTitle" />
				</ListItem>
				<Divider />
				<ListItem button key={8} onClick={handleClick}>
					<Description className="drawerIcon" />
					<ListItemText primary={t('report')} className="listItemTitle" />
					{open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Divider />
				<Collapse in={open} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<ListItem
							button
							key={1}
							/*
							 *The reason we use onClick instead of component={Link} is
							 *if we navigate from edit-report to create-report
							 *we store the report values in the same context with the same keys
							 *so if we click on create-report coming from edit-report, input values
							 *will remain the same, therefore we need to refresh to clear
							 *the inputs for creating a new report
							 */
							onClick={() => changeLocation('/create-report')}
						>
							<NoteAddOutlined className="drawerIcon" />
							<ListItemText
								primary={t('new_report')}
								className="listItemTitle"
							/>
						</ListItem>
						<Divider />
						<ListItem button key={2} component={Link} to={'/my-reports/'}>
							<ListAlt className="drawerIcon" />
							<ListItemText
								primary={t('my_reports')}
								className="listItemTitle"
							/>
						</ListItem>
						<Divider />
						{
							//This link should be visible for all users except cell users
							cellUser ? null : (
								<ListItem
									button
									key={3}
									component={Link}
									to={'/project-reports/'}
								>
									<ListAlt className="drawerIcon" />
									<ListItemText
										primary={t('project_reports')}
										className="listItemTitle"
									/>
								</ListItem>
							)
						}
						<Divider />
						{
							//This link should be visible for all users except field users with no coordo position
							isField || cellUser ? null : (
								<ListItem
									button
									key={4}
									component={Link}
									to={'/mission-reports/'}
								>
									<ListAlt className="drawerIcon" />
									<ListItemText
										primary={t('mission_reports')}
										className="listItemTitle"
									/>
								</ListItem>
							)
						}
						<Divider />
						{
							//This link should be visible for all users except field users with no coordo position
							isField || isCoordo ? null : (
								<ListItem button key={5} component={Link} to={'/cell-reports/'}>
									<ListAlt className="drawerIcon" />
									<ListItemText
										primary={t('cell_reports')}
										className="listItemTitle"
									/>
								</ListItem>
							)
						}
						<Divider />
						{currentUser.garec && (
							<ListItem button key={6} component={Link} to={'/garec-reports/'}>
								<ListAlt className="drawerIcon" />
								<ListItemText
									primary={t('garec_reports')}
									className="listItemTitle"
								/>
							</ListItem>
						)}
						<Divider />
						{(currentUser.garec || currentUser.behaviourFocalPoint) && (
							<ListItem button key={7} component={Link} to={'/all-reports/'}>
								<ListAlt className="drawerIcon" />
								<ListItemText
									primary={t('all_reports')}
									className="listItemTitle"
								/>
							</ListItem>
						)}
					</List>
				</Collapse>
				<Divider />
				<ListItem
					button
					key={10}
					component={Link}
					onClick={handleRedirect}
					to="/"
				>
					<Lightbulb className="drawerIcon" />
					<ListItemText primary={t('Intranet')} className="listItemTitle" />
				</ListItem>
				<Divider />
				<Divider />
				<ListItem button key={11} component={Link} to="/guidelines">
					<Info className="drawerIcon" />
					<ListItemText primary={t('guidelines')} className="listItemTitle" />
				</ListItem>
				<Divider />
			</List>
		</div>
	)
}

export default Drawer

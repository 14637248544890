import React from 'react'
import { Navigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { useCurrentUser } from '../../CurrentUserContext'
import ReportTable from '../../UI/ReportTable'
import Header from '../../UI/Header'
import Progress from '../../UI/Progress'

const GAREC_REPORTS = loader('./graphql/garecReports.graphql')

const GarecReports = () => {
	const currentUser = useCurrentUser()
	const { data: { garecReports = null } = {}, loading } =
		useQuery(GAREC_REPORTS)
	if (!currentUser.garec) return <Navigate to="/" />
	return (
		<>
			<Header />
			{loading ? (
				<Progress />
			) : (
				<>{garecReports && <ReportTable reports={garecReports} />}</>
			)}
		</>
	)
}

export default GarecReports

/**
 * This function takes currentUser as an argument, we look if user object has 'project' value
 * is true, if yes it means user is a field user
 * then we check if user is a coordo by looking into user.groups array
 * if it has a group called 'coordo' we return false means user is a field but NOT a  coordo user
 * if coordo is false it means user is a field user
 *
 */
const isFieldUser = ({ currentUser }) => {
	if (currentUser.project) {
		const coordo = currentUser.groups.find(({ group }) => group === 'coordo')

		if (coordo) {
			return false
		} else {
			return true
		}
	}
}

export default isFieldUser

import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import Progress from '../UI/Progress'
const CURRENT_USER = loader('./graphql/currentUser.graphql')

export const CurrentUserContext = React.createContext()

export const CurrentUserProvider = ({ children }) => {
	const navigate = useNavigate() // Initialize navigate
	const { data: { currentUser = null } = {}, loading } = useQuery(CURRENT_USER)

	// Redirect to /error if currentUser is falsy
	// useEffect(() => {
	// 	if (!currentUser) {
	// 		navigate('/')
	// 	}
	// 	//If user has not been configured as one of the main 5 profiles, redirect to the error access page
	// 	else if (
	// 		currentUser &&
	// 		!currentUser?.garec &&
	// 		!currentUser?.behaviourFocalPoint &&
	// 		!currentUser?.cell &&
	// 		!currentUser?.project
	// 	) {
	// 		navigate('/access-error')
	// 	}
	// }, [currentUser])

	if (loading) return <Progress />

	return (
		<CurrentUserContext.Provider value={currentUser}>
			{children}
		</CurrentUserContext.Provider>
	)
}

export const useCurrentUser = () => React.useContext(CurrentUserContext)

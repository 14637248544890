import React from 'react'
import Header from '../../UI/Header'

const Page404 = () => {
	return (
		<>
			<Header />
			<div>
				<h1>404</h1>
				<p>The page you are looking for can not be found</p>
			</div>
		</>
	)
}

export default Page404

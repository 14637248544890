import React from 'react'
import { useTranslation } from 'react-i18next'
import {
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
} from '@mui/material/'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material/'
import './styles.css'

const GeneralInfoSummary = ({ report }) => {
	const { t } = useTranslation()

	const values = [
		{ text: t('report_date'), mainKey: 'reportDate', subKey: false },
		{ text: t('incident_mission'), mainKey: 'mission', subKey: 'name' },
		{ text: t('incident_project'), mainKey: 'project', subKey: 'name' },
		{ text: t('report_to'), mainKey: 'reportedTo', subKey: 'to' },
		{
			text: t('investigation_undertaken'),
			mainKey: 'invistegationTaken',
			subKey: false,
		},
		{ text: t('investigated_by'), mainKey: 'investigatedBy', subKey: 'to' },
		{
			text: t('why_no_invistigation'),
			mainKey: 'notTakenInvistigationReason',
			subKey: 'reason',
		},
		{
			text: t('garec_advice'),
			mainKey: 'adviceRequestedFromGarec',
			subKey: false,
		},
	]

	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<Typography className="typography">{t('general_info')}</Typography>
			</AccordionSummary>
			<AccordionDetails className="accordionDetails">
				{values.map(({ text, mainKey, subKey }, i) => {
					const textToRender =
						report[mainKey] && subKey
							? t(report[mainKey][subKey])
							: t(report[mainKey])
					return (
						textToRender && (
							<div key={i}>
								<Typography>{`- ${text}`}</Typography>
								<Typography className="typography-answer">{`${textToRender}`}</Typography>
							</div>
						)
					)
				})}
			</AccordionDetails>
		</Accordion>
	)
}

export default GeneralInfoSummary

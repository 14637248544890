import React, { useContext, useEffect } from 'react'
import { AppContext } from './Context'
import Header from '../../UI/Header'
import GeneralInformation from './GeneralInformation'
import Complainant from './Complainant'
import AddSubject from './AddSubject'
import AffectedPerson from './AffectedPerson'
import Closure from './Closure'
import Conclusion from './Conclusion'
import Subject from './Subject'
import './styles.css'

const handleSteps = (step) => {
	switch (step) {
		case 0:
			return <GeneralInformation />
		case 1:
			return <Complainant />
		case 2:
			return <AffectedPerson />
		case 3:
			return <Subject />
		case 4:
			return <Conclusion />
		case 5:
			return <AddSubject />
		case 6:
			return <Closure />
		default:
			throw new Error('Unknown step')
	}
}

const ReportForm = () => {
	const { activeStep } = useContext(AppContext)
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [activeStep])
	return (
		<>
			<Header />
			<div className="formContainer">{handleSteps(activeStep)}</div>
		</>
	)
}

export default ReportForm

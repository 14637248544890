import React from 'react'
import msfLogo from '../../../images/msflogo-trans-background.png'
import './index.css'

const Footer = () => {
	return (
		<footer className="footer">
			<div style={{ paddingLeft: '20px' }}>
				<a href="/#" className="footerLink">
					<img src={msfLogo} alt="msfLogo" className="logo" />
					<p>BRS</p>
				</a>
			</div>
		</footer>
	)
}

export default Footer

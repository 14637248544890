import React from 'react'
import { Navigate } from 'react-router-dom'
import { useCurrentUser } from '../../CurrentUserContext'
import Header from '../../UI/Header'

const Profile = () => {
	const currentUser = useCurrentUser()

	if (!currentUser) return <Navigate to={'/'} />

	return (
		<>
			<Header />
			{currentUser && (
				<>
					<h2>{currentUser.email}</h2>
				</>
			)}
		</>
	)
}

export default Profile

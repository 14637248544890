import React from 'react'
import { useTranslation } from 'react-i18next'
import {
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
} from '@mui/material/'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material/'
import './styles.css'

const ComplainantSummary = ({ report }) => {
	const { complainant } = report
	const { t } = useTranslation()

	const values = [
		{ text: t('complainant_gender'), mainKey: 'gender', subKey: 'gender' },
		{ text: t('complainant_hrStatus'), mainKey: 'hrStatus', subKey: 'status' },
		{
			text: t('complainant_field_position'),
			mainKey: 'fieldPosition',
			subKey: 'position',
		},
		{
			text: t('complainant_hq_position'),
			mainKey: 'hqPosition',
			subKey: 'position',
		},
		{
			text: t('complainant_contracting_office'),
			mainKey: 'contractingOffice',
			subKey: 'country',
		},
		{
			text: t('complainant_msf_experience'),
			mainKey: 'experience',
			subKey: 'experience',
		},
		{
			text: t('complainant_minor'),
			mainKey: 'isMinor',
			subKey: false,
		},
		{
			text: t('complainant_nationality'),
			mainKey: 'nationality',
			subKey: 'country',
		},
	]

	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<Typography className="typography">{t('complainant')}</Typography>
			</AccordionSummary>
			<AccordionDetails className="accordionDetails">
				{values.map(({ text, mainKey, subKey }, i) => {
					const textToRender =
						complainant[mainKey] && subKey
							? t(complainant[mainKey][subKey])
							: t(complainant[mainKey])
					return (
						textToRender && (
							<div key={i}>
								<Typography>{`- ${text}`}</Typography>
								<Typography className="typography-answer">{`${textToRender}`}</Typography>
							</div>
						)
					)
				})}

				{!report.affectedPerson && (
					//If report.affectedPerson is false, it means complainant is the affectedPerson
					<>
						<Typography>{`- ${t(
							'is_complainant_effected_person'
						)}`}</Typography>
						<Typography>{t('yes')}</Typography>
					</>
				)}
			</AccordionDetails>
		</Accordion>
	)
}

export default ComplainantSummary

import React, { useEffect, useCallback, useContext, useState } from 'react'
import { AppContext } from './Context'
import { useTranslation } from 'react-i18next'
// import { useCurrentUser } from '../../CurrentUserContext'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import BackButton from './ReusableInputs/BackButton'
import NextButton from './ReusableInputs/NextButton'
import {
	Box,
	FormControl,
	FormLabel,
	TextField,
	Grid,
	MenuItem,
	InputLabel,
	Select,
	LinearProgress,
} from '@mui/material'
import RadioGroupInput from './ReusableInputs/RadioGroupInput'
import MultiSelect from './ReusableInputs/MultiSelect'
import { subjectInitialValues } from '../EditReportForm/initialValues'
import { isFieldPosition, isHqPosition, sortAlphabetically } from './functions'
import './styles.css'

const ALL_COUNTRIES = loader('./graphql/allCountries.graphql')
const EXPERIENCES = loader('./graphql/experiences.graphql')
const FIELD_POSITIONS = loader('./graphql/fieldPositions.graphql')
const GENDERS = loader('./graphql/genders.graphql')
const MISSIONS = loader('./graphql/missions.graphql')
const HR_STATUS = loader('./graphql/hrStatusInitialOptions.graphql')
const HQ_POSITIONS = loader('./graphql/hqPositions.graphql')
const IPOB_LIST = loader('./graphql/allIpobTypologies.graphql')

const Subject = ({ report }) => {
	const { t } = useTranslation()
	const [hrStatusArr, setHrStatusArr] = useState()
	// const currentUser = useCurrentUser()
	const {
		formValues,
		handleChange,
		allIpobTypologiesExternalLink,
		variant,
		margin,
		yesAndNoRadioOptions,
		numberOfSubjects,
	} = useContext(AppContext)
	const { data: { allCountries = null } = {} } = useQuery(ALL_COUNTRIES)
	const { data: { experiences = null } = {} } = useQuery(EXPERIENCES)
	const { data: { fieldPositions = null } = {} } = useQuery(FIELD_POSITIONS)
	const { data: { genders = null } = {} } = useQuery(GENDERS)
	const { data: { hrStatusInitialOptions = null } = {} } = useQuery(HR_STATUS)
	const { data: { hqPositions = null } = {} } = useQuery(HQ_POSITIONS)
	const { data: { missions = null } = {} } = useQuery(MISSIONS)
	const { data: { allIpobTypologies = null } = {}, loading } =
		useQuery(IPOB_LIST)

	const {
		[`subject_Gender_${numberOfSubjects}`]: subject_Gender,
		[`subject_HrStatus_${numberOfSubjects}`]: subject_HrStatus,
		[`subject_CreateHrStatus_${numberOfSubjects}`]: subject_CreateHrStatus,
		[`subject_ContractingOffice_${numberOfSubjects}`]:
			subject_ContractingOffice,
		[`subject_MsfExperience_${numberOfSubjects}`]: subject_MsfExperience,
		[`subject_IsMinor_${numberOfSubjects}`]: subject_IsMinor,
		[`subject_Nationality_${numberOfSubjects}`]: subject_Nationality,
		[`subject_caseIpob_${numberOfSubjects}`]: subject_caseIpob,
		[`subject_Conclusion_${numberOfSubjects}`]: subject_Conclusion,
		[`subject_Position_${numberOfSubjects}`]: subject_Position,
		complainant_IsTheEffectedPerson,
		generalInfo_investigationUndertaken,
		generalInfo_affectedPersonIdentified,
	} = formValues

	// Check if all values are not empty and if there are some errors
	const isError = useCallback(
		() =>
			Object.keys({
				subject_Gender,
				subject_HrStatus,
				subject_CreateHrStatus,
				subject_ContractingOffice,
				subject_MsfExperience,
				subject_IsMinor,
				subject_Nationality,
				subject_caseIpob,
				subject_Conclusion,
				subject_Position,
			}).some((name) => {
				return (
					Boolean(
						(formValues[`${name}_${numberOfSubjects}`]?.required &&
							!formValues[`${name}_${numberOfSubjects}`]?.value) ||
							formValues[`${name}_${numberOfSubjects}`]?.error
					) ||
					(subject_HrStatus?.value === 'other' &&
						!subject_CreateHrStatus?.value?.trim()?.length) ||
					!Boolean(subject_caseIpob?.value?.length) ||
					((isFieldPosition({
						choosenHrStatusId: subject_HrStatus?.value,
						hrStatusArr,
					}) ||
						isHqPosition({
							choosenHrStatusId: subject_HrStatus?.value,
							hrStatusArr,
						})) &&
						(!subject_Position?.value ||
							!subject_ContractingOffice?.value ||
							!subject_MsfExperience?.value))
				)
			}),
		[
			formValues,
			numberOfSubjects,
			subject_Gender,
			subject_HrStatus,
			subject_CreateHrStatus,
			subject_ContractingOffice,
			subject_MsfExperience,
			subject_IsMinor,
			subject_Nationality,
			subject_caseIpob,
			subject_Conclusion,
			subject_Position,
		]
	)
	//Here we set hrStatusArr once the component loads
	//the reason we write this into a state, is we have fixed list of values as options
	//for the user when submitting a record, but user can enter a status manually
	//and create an entry in the DB & this values should be related to this report only
	useEffect(() => {
		if (hrStatusInitialOptions) {
			if (report) {
				const find = hrStatusInitialOptions.find(
					({ id }) => id === report.subjects[numberOfSubjects - 1]?.hrStatus?.id
				)
				//If user is creating a new subject when modifying report
				if (find || !report.subjects[numberOfSubjects - 1]) {
					setHrStatusArr([
						...hrStatusInitialOptions,
						{ id: 'other', status: 'other' },
					])
				} else {
					setHrStatusArr([
						...hrStatusInitialOptions,
						report.subjects[numberOfSubjects - 1].hrStatus,
						{ id: 'other', status: 'other' },
					])
				}
			} else {
				setHrStatusArr([
					...hrStatusInitialOptions,
					{ id: 'other', status: 'other' },
				])
			}
		}
	}, [hrStatusInitialOptions, report])

	/**
	 *In this case user is editing the form, we go over the form values and overwrite to the
	 *initial values we exported earlier
	 */
	useEffect(() => {
		if (report) {
			const { subjects } = report
			const currentSubject = subjects[numberOfSubjects - 1]
			if (subjects[numberOfSubjects - 1]) {
				subjectInitialValues.map(({ name, mainKey, subKey }) => {
					//If The key === 'affectedPerson_Position' means we need to know the position of this
					//user was field or hq as we have one key 'affectedPerson_Position' handles both cases
					//Either fieldPosition or hqPosition can be true
					if (name === `subject_Position`) {
						handleChange({
							target: {
								name: `${name}_${numberOfSubjects}`,
								value: currentSubject.fieldPosition
									? currentSubject.fieldPosition.id
									: currentSubject.hqPosition
									? currentSubject.hqPosition.id
									: '',
							},
						})
					} else if (subKey === `array`) {
						handleChange({
							target: {
								name: `${name}_${numberOfSubjects}`,
								value: currentSubject[mainKey].map(({ id }) => id),
							},
						})
					} else if (subKey) {
						handleChange({
							target: {
								name: `${name}_${numberOfSubjects}`,
								value: currentSubject[mainKey]
									? currentSubject[mainKey][subKey]
									: currentSubject[mainKey],
							},
						})
						//If subKey is false, it means there is no subkey and we need to use the mainKey directly
						//This works in the case of values === Boolean
					} else if (!subKey) {
						handleChange({
							target: {
								name: `${name}_${numberOfSubjects}`,
								value: Boolean(report[mainKey]),
							},
						})
					}
					if (currentSubject.conclusion) {
						handleChange({
							target: {
								name: `subject_Conclusion_${numberOfSubjects}`,
								value: true,
							},
						})
					}
				})
			}
		}
	}, [report, allIpobTypologies])

	return (
		<>
			{hrStatusArr && (
				<>
					<h2>{`${t('subject_of_complaint')} #${numberOfSubjects}`}</h2>
					<Grid
						container
						spacing={2}
						direction="column"
						justifyContent="center"
						alignItems="left"
						columns={16}
					>
						<Grid item xs={12}>
							<FormControl fullWidth required={true}>
								<InputLabel id="demo-simple-select-label">
									{t('subject_gender')}
								</InputLabel>
								<Select
									id={`subject_Gender${numberOfSubjects}`}
									value={subject_Gender?.value || ''}
									name={`subject_Gender_${numberOfSubjects}`}
									label={t('subject_gender')}
									onChange={handleChange}
								>
									{genders &&
										genders.map(({ id, gender }) => (
											<MenuItem value={id} key={id}>
												{t(gender)}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControl fullWidth required={true}>
								<InputLabel id="demo-simple-select-label">
									{t('subject_hrStatus')}
								</InputLabel>
								<Select
									id={`subject_HrStatus${numberOfSubjects}`}
									value={subject_HrStatus?.value || ''}
									name={`subject_HrStatus_${numberOfSubjects}`}
									label={t('subject_hrStatus')}
									onChange={handleChange}
								>
									{hrStatusArr &&
										hrStatusArr.map(({ status, id }) => (
											<MenuItem value={id} key={id}>
												{t(status)}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</Grid>
						{subject_HrStatus?.value === 'other' ? (
							<Grid item xs={12} sm={6}>
								<TextField
									variant={variant}
									margin={margin}
									fullWidth
									label={t('other')}
									value={subject_CreateHrStatus?.value || ''}
									name={`subject_CreateHrStatus_${numberOfSubjects}`}
									placeholder={t('other')}
									onChange={handleChange}
									required={subject_HrStatus?.value === 'other'}
								/>
							</Grid>
						) : null}
						{subject_HrStatus?.value &&
							subject_HrStatus?.value !== '' &&
							isFieldPosition({
								choosenHrStatusId: subject_HrStatus?.value,
								hrStatusArr,
							}) && (
								<Grid item xs={12}>
									<FormControl
										fullWidth
										required={isFieldPosition({
											choosenHrStatusId: subject_HrStatus?.value,
											hrStatusArr,
										})}
									>
										<InputLabel id="demo-simple-select-label">
											{t('subject_field_position')}
										</InputLabel>
										<Select
											id={`subjectFieldPosition${numberOfSubjects}`}
											value={subject_Position?.value || ''}
											name={`subject_Position_${numberOfSubjects}`}
											label={t('subject_field_position')}
											onChange={handleChange}
										>
											{fieldPositions &&
												fieldPositions.map(({ id, position }) => (
													<MenuItem value={id} key={id}>
														{t(position)}
													</MenuItem>
												))}
										</Select>
									</FormControl>
								</Grid>
							)}

						{subject_HrStatus?.value &&
							subject_HrStatus?.value !== '' &&
							isHqPosition({
								choosenHrStatusId: subject_HrStatus?.value,
								hrStatusArr,
							}) && (
								<Grid item xs={12}>
									<FormControl
										fullWidth
										required={isHqPosition({
											choosenHrStatusId: subject_HrStatus?.value,
											hrStatusArr,
										})}
									>
										<InputLabel id="demo-simple-select-label">
											{t('subject_hq_position')}
										</InputLabel>
										<Select
											id={`subjectHqPosition${numberOfSubjects}`}
											value={subject_Position?.value || ''}
											name={`subject_Position_${numberOfSubjects}`}
											label={t('subject_hq_position')}
											onChange={handleChange}
										>
											{hqPositions &&
												hqPositions.map(({ id, position }) => (
													<MenuItem value={id} key={id}>
														{t(position)}
													</MenuItem>
												))}
										</Select>
									</FormControl>
								</Grid>
							)}
						{subject_HrStatus?.value &&
							subject_HrStatus?.value !== '' &&
							(isFieldPosition({
								choosenHrStatusId: subject_HrStatus?.value,
								hrStatusArr,
							}) ||
								isHqPosition({
									choosenHrStatusId: subject_HrStatus?.value,
									hrStatusArr,
								})) && (
								<>
									<Grid item xs={12}>
										<FormControl
											fullWidth
											required={
												isFieldPosition({
													choosenHrStatusId: subject_HrStatus?.value,
													hrStatusArr,
												}) ||
												isHqPosition({
													choosenHrStatusId: subject_HrStatus?.value,
													hrStatusArr,
												})
											}
										>
											<InputLabel id="demo-simple-select-label">
												{t('subject_contracting_office')}
											</InputLabel>
											<Select
												id={`subject_ContractingOffice${numberOfSubjects}`}
												value={subject_ContractingOffice?.value || ''}
												name={`subject_ContractingOffice_${numberOfSubjects}`}
												label={t('subject_contracting_office')}
												onChange={handleChange}
											>
												{allCountries &&
													sortAlphabetically({
														arr: allCountries,
														sortOn: 'country',
													}).map(({ id, country }) => (
														<MenuItem value={id} key={id}>
															{country}
														</MenuItem>
													))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<FormControl
											fullWidth
											required={
												isFieldPosition({
													choosenHrStatusId: subject_HrStatus?.value,
													hrStatusArr,
												}) ||
												isHqPosition({
													choosenHrStatusId: subject_HrStatus?.value,
													hrStatusArr,
												})
											}
										>
											<InputLabel id="demo-simple-select-label">
												{t('subject_msf_experience')}
											</InputLabel>
											<Select
												id={`subject_MsfExperience${numberOfSubjects}`}
												value={subject_MsfExperience?.value || ''}
												name={`subject_MsfExperience_${numberOfSubjects}`}
												label={t('subject_msf_experience')}
												onChange={handleChange}
											>
												{experiences &&
													experiences.map(({ id, experience }) => (
														<MenuItem value={id} key={id}>
															{t(experience)}
														</MenuItem>
													))}
											</Select>
										</FormControl>
									</Grid>
								</>
							)}
						{(subject_CreateHrStatus?.value === 'other' &&
							!subject_CreateHrStatus?.value) ||
						(subject_HrStatus?.value &&
							subject_HrStatus?.value === '') ? null : (
							<>
								<Grid item xs={12}>
									<FormControl>
										<FormLabel id="demo-radio-buttons-group-label">
											{t('subject_minor')}
										</FormLabel>
										<RadioGroupInput
											id={`subject_IsMinor_${numberOfSubjects}`}
											name={`subject_IsMinor_${numberOfSubjects}`}
											//If value is undefined, so isMinor is false
											value={Boolean(subject_IsMinor?.value)}
											array={yesAndNoRadioOptions}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<FormControl fullWidth required={true}>
										<InputLabel id="demo-simple-select-label">
											{t('subject_nationality')}
										</InputLabel>
										<Select
											id={`subject_Nationality${numberOfSubjects}`}
											value={subject_Nationality?.value || ''}
											name={`subject_Nationality_${numberOfSubjects}`}
											label={t('subject_nationality')}
											onChange={handleChange}
										>
											{allCountries &&
												sortAlphabetically({
													arr: allCountries,
													sortOn: 'country',
												}).map(({ id, country }) => (
													<MenuItem value={id} key={id}>
														{country}
													</MenuItem>
												))}
										</Select>
									</FormControl>
								</Grid>
							</>
						)}
						<Grid item xs={12}>
							{/* <FormGroup> */}
							{/* <FormLabel>{t('ibob_case')}</FormLabel> */}
							{loading ? (
								<LinearProgress color="inherit" />
							) : (
								allIpobTypologies && (
									<>
										<MultiSelect
											//Remove value 'None' from the array
											array={allIpobTypologies.filter(
												({ case: ipobCase }) => ipobCase !== 'None'
											)}
											objKey="case"
											initialValue={subject_caseIpob?.value || []}
											name={`subject_caseIpob_${numberOfSubjects}`}
											label="ibob_case"
										/>
										<a
											className="hyper-link"
											href={allIpobTypologiesExternalLink()}
											target="_blank"
											rel="noreferrer"
										>
											{t('ipob_definition')}
										</a>
									</>
								)
							)}
						</Grid>
						<Grid item xs={12}>
							{
								//If there was not investigation taken, hide radio button for adding a conclusion
								generalInfo_investigationUndertaken.value && (
									<FormControl>
										<FormLabel id="demo-radio-buttons-group-label">
											{t('subject_conclusion')}
										</FormLabel>
										<RadioGroupInput
											id={`subject_Conclusion${numberOfSubjects}`}
											name={`subject_Conclusion_${numberOfSubjects}`}
											value={subject_Conclusion?.value || false}
											array={yesAndNoRadioOptions}
										/>
									</FormControl>
								)
							}
						</Grid>
					</Grid>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<BackButton
							number={
								numberOfSubjects > 1
									? -2
									: complainant_IsTheEffectedPerson.value
									? 2
									: numberOfSubjects === 1 &&
									  generalInfo_affectedPersonIdentified.value === false
									? 3
									: 1
							}
							removeSubject={numberOfSubjects === 1 ? false : true}
						/>
						<NextButton
							error={
								// !(subject_caseIpob && subject_caseIpob.value.length) ||
								isError()
							}
							number={subject_Conclusion?.value ? 1 : 2}
						/>
					</Box>
				</>
			)}
		</>
	)
}

export default Subject

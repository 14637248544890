import React from 'react'
import { useTranslation } from 'react-i18next'
import {
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
} from '@mui/material/'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material/'
import './styles.css'

const AffectedPersonSummary = ({ report }) => {
	const { affectedPerson } = report
	const { t } = useTranslation()

	const values = [
		{ text: t('affectedPerson_gender'), mainKey: 'gender', subKey: 'gender' },
		{
			text: t('affectedPerson_hrStatus'),
			mainKey: 'hrStatus',
			subKey: 'status',
		},
		{
			text: t('affectedPerson_field_position'),
			mainKey: 'fieldPosition',
			subKey: 'position',
		},
		{
			text: t('affectedPerson_hq_position'),
			mainKey: 'hqPosition',
			subKey: 'position',
		},
		{
			text: t('affectedPerson_contracting_office'),
			mainKey: 'contractingOffice',
			subKey: 'country',
		},
		{
			text: t('affectedPerson_msf_experience'),
			mainKey: 'experience',
			subKey: 'experience',
		},
		{
			text: t('affectedPerson_minor'),
			mainKey: 'isMinor',
			subKey: false,
		},
		{
			text: t('affectedPerson_nationality'),
			mainKey: 'nationality',
			subKey: 'country',
		},
	]

	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<Typography className="typography">{t('affected_person')}</Typography>
			</AccordionSummary>
			<AccordionDetails className="accordionDetails">
				{values.map(({ text, mainKey, subKey }, i) => {
					const textToRender =
						affectedPerson[mainKey] && subKey
							? t(affectedPerson[mainKey][subKey])
							: t(affectedPerson[mainKey])
					return (
						textToRender && (
							<div key={i}>
								<Typography>{`- ${text}`}</Typography>
								<Typography className="typography-answer">{`${textToRender}`}</Typography>
							</div>
						)
					)
				})}
			</AccordionDetails>
		</Accordion>
	)
}

export default AffectedPersonSummary

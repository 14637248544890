import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
	MuiContainer: {
		padding: '50px 0 0',
		textAlign: 'left',
	},
	formControl: {
		marginLeft: '50px',
		marginBottom: '50px',
		color: '#35463D',
		borderColor: 'red',
		minWidth: 250,
		'& label': {
			backgroundColor: '#F9F9F9',
			paddingLeft: 5,
			paddingRight: 5,
		},
		'& label.Mui-focused': {
			color: '#35463D',
		},
	},
})

export default useStyles

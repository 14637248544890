import React, { createContext, useReducer } from 'react'
import { addSubjectNumberToInitialValues } from './initialValues'

const isText = RegExp(/^[A-Z ]+$/i)
// const isNumber = RegExp(/^\d+$/)

// Applied to all fields
const variant = 'standard'
const margin = 'normal'
const yesAndNoRadioOptions = [
	{ label: 'yes', value: true },
	{ label: 'no', value: false },
]

let numberOfSubjects = 1

export const AppContext = createContext({
	activeStep: 0,
	formValues: addSubjectNumberToInitialValues({ numberOfSubjects }),
	handleChange() {},
	handleNext() {},
	handleBack() {},
	allIpobTypologiesExternalLink() {},
	variant,
	margin,
	yesAndNoRadioOptions,
	numberOfSubjects,
})

function reducer(state, action) {
	let formValues = { ...state.formValues }
	if (action.removeSubject) {
		//Remove all the keys&values for the subject which will be removed
		for (const property in formValues) {
			if (property.includes(numberOfSubjects)) {
				delete formValues[property]
			}
		}
		numberOfSubjects--
	}
	switch (action.type) {
		case 'increase':
			return {
				...state,
				activeStep: state.activeStep + action.number,
			}
		case 'decrease':
			return {
				...state,
				activeStep: state.activeStep - action.number,
			}
		case 'form-value':
			return {
				...state,
				formValues: {
					...formValues,
					[action.name]: {
						...formValues[action.name],
						value: action.fieldValue,
					},
				},
			}
		case 'form-error':
			return {
				...state,
				formValues: {
					...formValues,
					[action.name]: {
						...formValues[action.name],
						error: action.error,
					},
				},
			}

		default:
			return state
	}
}

export function StepsProvider({ children }) {
	const [{ activeStep, formValues }, dispatch] = useReducer(reducer, {
		activeStep: 0,
		formValues: addSubjectNumberToInitialValues({ numberOfSubjects }),
	})

	// Proceed to next step
	const handleNext = ({ number, addSubject, removeSubject }) => {
		if (addSubject) {
			numberOfSubjects++
		}
		// if (removeSubject) {
		// 	numberOfSubjects--
		// }
		return dispatch({ type: 'increase', number, removeSubject })
	}
	// Go back to prev step
	const handleBack = ({ number, removeSubject }) =>
		dispatch({ type: 'decrease', number, removeSubject })

	// Handle form change
	const handleChange = (event, checked) => {
		const { type, name, value } = event.target
		let definedValue = value
		//This is because Radio in MaterialUi returns string instead of Boolean
		if (type === 'radio') {
			if (value === 'true') {
				definedValue = true
			} else if (value === 'false') {
				definedValue = false
			}
		}
		// if (name === 'anotherSubject' && value === 'true') {
		// 	numberOfSubjects++
		// }

		const fieldValue = type === 'checkbox' ? checked : definedValue
		dispatch({ type: 'form-value', name, fieldValue })
		const fieldName = addSubjectNumberToInitialValues({ numberOfSubjects })[
			name
		]
		if (!fieldName) return

		const { required, validate, helperText } = fieldName

		let error = ''

		if (required && !fieldValue) error = 'This field is required'
		if (validate) {
			switch (validate) {
				case 'text':
					if (value && !isText.test(value))
						error = helperText || 'This field accepts text only.'
					break

				// case "number":
				//   if (value && !isNumber.test(value))
				//     error = helperText || "This field accepts numbers only."
				//   break

				case 'checkbox':
					if (!checked) error = helperText || 'Please provide a valid value.'
					break

				case 'select':
					if (!value) error = helperText || 'Please select a value.'
					break

				default:
					break
			}
		}

		dispatch({ type: 'form-error', name, error })
	}

	//This function is responsible for the external link to the ipob typologies and in which language
	const allIpobTypologiesExternalLink = () => {
		const language = window.localStorage.i18nextLng
		const englishLink =
			'https://msfintl.sharepoint.com/sites/ocb-intranet/_layouts/15/DocIdRedir.aspx?ID=ATA6V7EA5M2D-128388688-372'
		const frenchLink =
			'https://msfintl.sharepoint.com/sites/ocb-intranet/_layouts/15/DocIdRedir.aspx?ID=ATA6V7EA5M2D-128388688-373'
		const spanishLink =
			'https://msfintl.sharepoint.com/sites/ocb-intranet/_layouts/15/DocIdRedir.aspx?ID=ATA6V7EA5M2D-128388688-374'

		return language === 'fr'
			? frenchLink
			: language === 'es'
			? spanishLink
			: englishLink
	}

	return (
		<AppContext.Provider
			value={{
				activeStep,
				formValues,
				handleChange,
				handleNext,
				handleBack,
				allIpobTypologiesExternalLink,
				variant,
				margin,
				yesAndNoRadioOptions,
				numberOfSubjects,
			}}
		>
			<div className="mui-step-form">{children}</div>
		</AppContext.Provider>
	)
}

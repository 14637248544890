import React from 'react'
import { useQuery } from '@apollo/client'
import { useCurrentUser } from '../../CurrentUserContext'
// import { useTranslation } from 'react-i18next'
import { loader } from 'graphql.macro'
import ReportTable from '../../UI/ReportTable'
import Header from '../../UI/Header'
import Progress from '../../UI/Progress'

const REPORTS_BY_USER = loader('./graphql/reportsByUser.graphql')

const MyReports = () => {
	const currentUser = useCurrentUser()
	// const { t } = useTranslation()

	const { data: { reportsByUser = null } = {}, loading } = useQuery(
		REPORTS_BY_USER,
		{
			variables: {
				userId: currentUser && currentUser.id,
			},
		}
	)

	return (
		<>
			<Header />
			{loading ? (
				<Progress />
			) : (
				reportsByUser && (
					<ReportTable reports={reportsByUser} myReports={true} />
				)
			)}
		</>
	)
}

export default MyReports

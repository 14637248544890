import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'
import { Container } from '@mui/material'
import Header from '../../UI/Header'
import guidelines_en from './guidelines-files/Guideline Inappropriate  Abusive case report.docx'

const Guidelines = () => {
	const currentUser = useCurrentUser()
	const { t } = useTranslation()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	if (!currentUser) return <Navigate to="/" />
	return (
		<>
			<Header />
			<Container maxWidth="md">
				<h1>{t('guidelines')}</h1>
				<a href={guidelines_en} download>
					{t('guidelines_download_en')}{' '}
				</a>
			</Container>
		</>
	)
}

export default Guidelines

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
	Button,
	Dialog,
	DialogTitle,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
} from '@mui/material/'

// This is a functional component named SimpleDialog that takes 'props' as its parameter.
function SimpleDialog(props) {
	// Destructuring assignment to extract 't' function from the 'useTranslation' hook.
	const { t } = useTranslation()
	// Destructuring assignment to extract properties from the 'props' object.
	const { open, elements, onClose, conclusion } = props
	// The component returns a Dialog component with specified properties.
	return (
		<Dialog onClose={onClose} open={open}>
			{/* 
        DialogTitle displays either the conclusion title or the default title based on the 'conclusion' prop.
      */}
			<DialogTitle>
				{conclusion ? t('conclusions_per_Ipob') : t('ibob_case')}
			</DialogTitle>
			{/* 
        List component to display a list of elements passed through the 'elements' prop.
      */}
			<List sx={{ pt: 0 }}>
				{/* 
          Mapping through each element in the 'elements' array.
        */}
				{elements?.map((s, i) => (
					<ListItem disableGutters key={i}>
						{/* 
              Depending on the 'conclusion' prop, different content is displayed.
            */}
						{conclusion ? (
							<ListItemButton>
								<ListItemText
									// Displaying primary text with the subject number.
									primary={`${t('subject')}:${i + 1}`}
									// Displaying secondary text with the ipobTypologies' case values joined by commas.
									secondary={
										s.conclusion
											? s.conclusion.ipobTypologies
													.map((i) => i.case)
													.join(', ')
											: '-'
									}
								/>
							</ListItemButton>
						) : (
							/* 
                Displaying ListItemButton with ListItemText for non-conclusion scenario.
              */
							<ListItemButton>
								<ListItemText
									// Displaying primary text with the subject number.
									primary={`${t('subject')}:${i + 1}`}
									// Displaying secondary text with the ipobTypologies' case values joined by commas.
									secondary={s.ipobTypologies.map((i) => i.case).join(', ')}
								/>
							</ListItemButton>
						)}
					</ListItem>
				))}
			</List>
		</Dialog>
	)
}

export default function DialogComponent(prpos) {
	// Destructuring assignment to extract 't' function from the 'useTranslation' hook.
	const { t } = useTranslation()
	const [open, setOpen] = React.useState(false)
	const { elements, conclusion } = prpos
	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<div>
			<Button variant="outlined" onClick={handleClickOpen}>
				{t('click_view_ipob')}
			</Button>
			<SimpleDialog
				elements={elements}
				open={open}
				onClose={handleClose}
				conclusion={conclusion}
			/>
		</div>
	)
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'
import {
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
} from '@mui/material/'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material/'
import './styles.css'

const ConclusionSummary = ({ conclusion }) => {
	const { t } = useTranslation()
	const currentUser = useCurrentUser()

	const values = [
		{
			text: t('conclusions_per_Ipob'),
			mainKey: 'ipobTypologies',
			subKey: 'case',
			anotherType: 'array',
		},
		{
			text: t('subject_outcome'),
			mainKey: 'outcomes',
			subKey: 'outcome',
			anotherType: 'array',
		},
		//Show "subject outcome hq" only if the user is Garec or behaviourFocalPoint
		currentUser &&
			(currentUser.garec || currentUser.behaviourFocalPoint) && {
				text: t('subject_outcome_hq'),
				mainKey: 'outcomesHq',
				subKey: 'outcome',
				anotherType: 'array',
			},
		{
			text: t('subject_legal_issues'),
			mainKey: 'legalStatutory',
			subKey: false,
		},
		{
			text: t('authorities_involvement'),
			mainKey: 'authorityInvolved',
			subKey: false,
		},
	]

	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<Typography className="typography">{t('conclusion')}</Typography>
			</AccordionSummary>
			<AccordionDetails className="accordionDetails">
				{values.map(({ text, mainKey, subKey, anotherType }, i) => {
					const textToRender =
						anotherType !== 'array' && conclusion[mainKey] && subKey
							? t(conclusion[mainKey][subKey])
							: anotherType !== 'array' && t(conclusion[mainKey])

					const finalTextToRender =
						anotherType === 'array'
							? conclusion[mainKey].map((elm) => t(elm[subKey])).toString()
							: textToRender
					return (
						finalTextToRender && (
							<div key={i}>
								<Typography>{`- ${text}`}</Typography>
								<Typography className="typography-answer">{`${finalTextToRender}`}</Typography>
							</div>
						)
					)
				})}
			</AccordionDetails>
		</Accordion>
	)
}

export default ConclusionSummary

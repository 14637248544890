import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useCurrentUser } from '../../CurrentUserContext'
import { useTranslation } from 'react-i18next'
import { loader } from 'graphql.macro'
import { FormControl, MenuItem, InputLabel, Select } from '@mui/material'
import ReportTable from '../../UI/ReportTable'
import Header from '../../UI/Header'
import Progress from '../../UI/Progress'
import { isCoordoUser, sortAlphabetically } from '../ReportForm/functions'

const REPORTS_BY_MISSION = loader('./graphql/reportsByMission.graphql')
const MISSIONS = loader('../ReportForm/graphql/missions.graphql')

const MissionReports = () => {
	const currentUser = useCurrentUser()
	const { t } = useTranslation()
	const [missionCode, setMissionCode] = useState(() => {
		return window.localStorage.getItem('missionCode')
	})
	const { data: { missions = null } = {}, loading: missionLoading } =
		useQuery(MISSIONS)

	const [
		fetchReportsByMissionCode,
		{ data: { reportsByMission = null } = {}, loading },
	] = useLazyQuery(REPORTS_BY_MISSION)

	useEffect(() => {
		if (currentUser) {
			//On initial render check if currentUser is a coordo, if so
			//fetchReportsByMissionCode by user's mission code (currentUser.project.mission.code)
			const isCoordo = isCoordoUser({ currentUser })
			if (isCoordo) {
				fetchReportsByMissionCode({
					variables: {
						missionCode: currentUser.project.mission.code,
					},
				})
			}
		}
	}, [currentUser])

	useEffect(() => {
		if (missionCode) {
			window.localStorage.setItem('missionCode', missionCode)
			fetchReportsByMissionCode({
				variables: {
					missionCode,
				},
			})
		}
	}, [missionCode])

	if (!currentUser) return <Navigate to={'/'} />

	return (
		<>
			<Header />
			{missionLoading ? (
				<Progress />
			) : (
				<>
					<FormControl
						style={{ minWidth: 220, marginBottom: '20px' }}
						disabled={Boolean(currentUser.project)}
					>
						<InputLabel id="demo-simple-select-label">
							{t('select_mission')}
						</InputLabel>
						<Select
							value={
								currentUser.project
									? currentUser.project.mission.code
									: missionCode
							}
							name="reportMission"
							label={t('select_mission')}
							onChange={({ target }) => setMissionCode(target.value)}
						>
							{missions &&
								sortAlphabetically({ arr: missions, sortOn: 'code' }).map(
									({ id, code, name }) => (
										<MenuItem
											value={code}
											key={id}
										>{`${code} - ${name}`}</MenuItem>
									)
								)}
						</Select>
					</FormControl>
					{loading ? (
						<Progress />
					) : (
						reportsByMission && <ReportTable reports={reportsByMission} />
					)}
				</>
			)}
		</>
	)
}

export default MissionReports

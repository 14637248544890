import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
	MuiCircularProgress: {
		top: '50%',
		left: '50%',
		color: '#FD0000',
	},
}))

export default useStyles

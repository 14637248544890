const useStyles = {
	accountCircleIcon: {
		color: 'black',
		fontSize: '30px',
	},
	accountCircleIconLink: {
		marginRight: '10px',
	},
	header: {
		boxShadow: 'none',
		borderBottom: '1px solid #ddd',
		backgroundColor: '#FEFEFE',
		paddingRight: '0',
		paddingLeft: '0',
		'@media (maxWidth: 900px)': {
			paddingLeft: 0,
		},
	},
	logo: {
		width: '60px',
		textAlign: 'left',
	},
	logoText: {
		color: '#4c4a4a',
		marginLeft: '10px',
		fontWeight: 'bold',
	},
	menuButton: {
		color: '#353535',
		fontSize: '34px',
		marginRight: '15px',
		hover: {
			backgroundColor: 'green',
		},
	},
	toolbar: {
		display: 'flex',
		paddingRight: '20px',
		paddingLeft: '20px',
	},
	drawerContainer: {
		padding: '20px 30px',
	},
	menuBottonsContainer: {
		display: 'flex',
		margin: '0 auto',
	},
	styledLink: {
		textDecoration: 'none',
		color: '#333333',
		fontWeight: 'bold',
	},
}

export default useStyles

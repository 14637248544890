export const initialValues = {
	generalInfo_reportDate: {
		value: new Date().toISOString().slice(0, 10),
		error: '',
		required: true,
	},
	generalInfo_incidentMission: {
		value: '',
		error: '',
		required: true,
	},
	generalInfo_incidentProject: {
		value: '',
		error: '',
		required: true,
	},
	generalInfo_caseReportedTo: {
		value: '',
		error: '',
		required: true,
	},
	generalInfo_investigationUndertaken: {
		value: true,
		error: '',
	},
	generalInfo_investigatedBy: {
		value: '',
		error: '',
		required: false,
	},
	generalInfo_noInvestigationReason: {
		value: '',
		error: '',
		required: false,
	},
	generalInfo_adviceAskedByGarec: {
		value: true,
		error: '',
	},
	generalInfo_complainantStatus: {
		value: '',
		error: '',
		required: true,
	},
	generalInfo_affectedPersonIdentified: {
		value: '',
		error: '',
	},
	complainant_Gender: {
		value: '',
		error: '',
		required: true,
	},
	complainant_HrStatus: {
		value: '',
		error: '',
		required: true,
	},
	complainant_CreateHrStatus: {
		value: '',
		error: '',
		required: false,
	},
	complainant_Position: {
		value: '',
		error: '',
		required: false,
	},
	complainant_ContractingOffice: {
		value: '',
		error: '',
		required: false,
	},
	complainant_MsfExperience: {
		value: '',
		error: '',
		required: false,
	},
	complainant_IsMinor: {
		value: false,
		error: '',
	},
	complainant_Nationality: {
		value: '',
		error: '',
		required: true,
	},
	complainant_IsTheEffectedPerson: {
		value: false,
		error: '',
	},
	affectedPerson_Gender: {
		value: '',
		error: '',
		required: true,
	},
	affectedPerson_HrStatus: {
		value: '',
		error: '',
		required: true,
	},
	affectedPerson_CreateHrStatus: {
		value: '',
		error: '',
		required: false,
	},
	affectedPerson_Position: {
		value: '',
		error: '',
		required: false,
	},
	affectedPerson_ContractingOffice: {
		value: '',
		error: '',
		required: false,
	},
	affectedPerson_MsfExperience: {
		value: '',
		error: '',
		required: false,
	},
	affectedPerson_IsMinor: {
		value: false,
		error: '',
	},
	affectedPerson_Nationality: {
		value: '',
		error: '',
		required: true,
	},
	closure_ClosingDate: {
		value: new Date().toISOString().slice(0, 10),
		error: '',
		required: true,
	},
	closure_WhereFactsFound: {
		value: '',
		error: '',
		required: true,
	},
	closure_Comment: {
		value: '',
		error: '',
		required: false,
	},
}

export const addSubjectNumberToInitialValues = ({ numberOfSubjects }) => {
	const subjectValues = {
		[`subject_Gender_${numberOfSubjects}`]: {
			value: '',
			error: '',
			required: true,
		},
		[`subject_HrStatus_${numberOfSubjects}`]: {
			value: '',
			error: '',
			required: true,
		},
		[`subject_CreateHrStatus_${numberOfSubjects}`]: {
			value: '',
			error: '',
			required: false,
		},
		[`subject_Position_${numberOfSubjects}`]: {
			value: '',
			error: '',
			required: false,
		},
		[`subject_ContractingOffice_${numberOfSubjects}`]: {
			value: '',
			error: '',
			required: false,
		},
		[`subject_MsfExperience_${numberOfSubjects}`]: {
			value: '',
			error: '',
			required: false,
		},
		[`subject_IsMinor_${numberOfSubjects}`]: {
			value: false,
			error: '',
		},
		[`subject_Nationality_${numberOfSubjects}`]: {
			value: '',
			error: '',
			required: true,
		},
		[`subject_caseIpob_${numberOfSubjects}`]: {
			value: [],
			error: '',
			required: true,
		},
		[`subject_Conclusion_${numberOfSubjects}`]: {
			value: false,
			error: '',
		},
		[`conclusion_PerIpob_${numberOfSubjects}`]: {
			value: [],
			error: '',
			required: true,
		},
		[`conclusion_SubjectOutcome_${numberOfSubjects}`]: {
			value: [],
			error: '',
			required: true,
		},
		[`conclusion_SubjectOutcomeHq_${numberOfSubjects}`]: {
			value: [],
			error: '',
			required: true,
		},
		[`conclusion_otherSubjectOutcome_${numberOfSubjects}`]: {
			value: false,
			error: '',
			required: false,
		},
		[`conclusion_createOtherSubjectOutcome_${numberOfSubjects}`]: {
			value: '',
			error: '',
			required: false,
		},
		[`conclusion_SubjectLegalIssues_${numberOfSubjects}`]: {
			value: false,
			error: '',
		},
		[`conclusion_AuthoritiesInvolvement_${numberOfSubjects}`]: {
			value: false,
			error: '',
		},
	}
	return { ...initialValues, ...subjectValues }
}

import React from 'react'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import { CurrentUserProvider } from './CurrentUserContext'
import { RedirectUserProvider } from './RedirectUserContext'

import { StepsProvider } from './pages/ReportForm/Context'
// Pages
import Home from './pages/Home'
import ReportForm from './pages/ReportForm'
import MyReports from './pages/MyReports'
import MissionReports from './pages/MissionReports'
import CellReports from './pages/CellReports'
import ProjectReports from './pages/ProjectReports'
import GarecReports from '././pages/GarecReports'
import AllReports from '././pages/AllReports'
import EditReportForm from './pages/EditReportForm'
import ReadReport from './pages/ReadReport'
import Profile from './pages/Profile'
import Page404 from './pages/Page404'
import Landing from './pages/Landing'
import Guidelines from './pages/Guidelines'
import Footer from './UI/Footer'
import AccessError from './pages/AccessError'
import Support from './pages/Support'

const App = () => {
	return (
		<div className="App">
			<div id="container">
				<StepsProvider>
					<CurrentUserProvider>
						<RedirectUserProvider>
							<Routes>
								<Route path="/home" exact element={<Home />} />
								<Route path="/create-report" exact element={<ReportForm />} />
								<Route path="/my-reports" exact element={<MyReports />} />
								<Route
									path="/mission-reports"
									exact
									element={<MissionReports />}
								/>
								<Route path="/cell-reports" exact element={<CellReports />} />
								<Route
									path="/project-reports"
									exact
									element={<ProjectReports />}
								/>
								<Route path="/garec-reports" exact element={<GarecReports />} />
								<Route path="/all-reports" exact element={<AllReports />} />
								<Route
									path="/edit-report/:id"
									exact
									element={<EditReportForm />}
								/>
								<Route path="/report/:id" exact element={<ReadReport />} />
								<Route path="/profile" exact element={<Profile />} />
								<Route path="/access-error" exact element={<AccessError />} />
								<Route path="/" exact element={<Landing />} />
								<Route path="/guidelines" exact element={<Guidelines />} />
								<Route path="/support" exact element={<Support />} />
								<Route path="*" element={<Page404 />} />
							</Routes>
						</RedirectUserProvider>
					</CurrentUserProvider>
				</StepsProvider>
			</div>
			<Footer />
		</div>
	)
}

export default App

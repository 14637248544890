import React from 'react'
import { Navigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useCurrentUser } from '../../CurrentUserContext'
// import { useTranslation } from 'react-i18next'
import { loader } from 'graphql.macro'
import ReportTable from '../../UI/ReportTable'
import Header from '../../UI/Header'
import Progress from '../../UI/Progress'

const ALL_REPORTS = loader('./graphql/allReports.graphql')

const AllReports = () => {
	const currentUser = useCurrentUser()
	// const { t } = useTranslation()

	const { data: { allReports = null } = {}, loading } = useQuery(ALL_REPORTS)

	if (!currentUser) return <Navigate to={'/'} />

	return (
		<>
			<Header />
			{loading ? (
				<Progress />
			) : (
				<>{allReports && <ReportTable reports={allReports} />}</>
			)}
		</>
	)
}

export default AllReports

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'
import { Navigate } from 'react-router-dom'
import Header from '../../UI/Header'
import './styles.css'

const Support = () => {
	const { t } = useTranslation()
	const currentUser = useCurrentUser()
	if (!currentUser) return <Navigate to="/" />
	return (
		<>
			<Header />
			<div className="supportContainer">
				<h2>{t('support')}</h2>
				<h4>{t('how_to_get_support')}</h4>
				<br />
				<p>
					{t('technical_support')}{' '}
					<a href={'mailto: Dr.Watson@brussels.msf.org'}> Dr Watson </a>{' '}
					{t('support_mail_info')}
				</p>
				<p>
					{t('other_support')} {t('or')}{' '}
					<a href={'mailto: maite.sotiau@brussels.msf.org'}>Maite Sotiau</a>
				</p>
			</div>
		</>
	)
}

export default Support

//List of fieldStatusArr
export const fieldHrStatusArr = [
	'Internationally Mobile Staff',
	'Locally Hired Staff',
	'Daily worker',
]

export const hqHrStatusArr = ['HQ staff']

export const internationalStaff = 'Internationally Mobile Staff'
export const nationalStaff = 'Locally Hired Staff'
export const hqStaff = 'HQ staff'
export const dailyWorker = 'Daily worker'
export const incentiveStaff = 'Incentive staff'
export const volunteers = 'Volunteers'
export const beneficiary = 'Beneficiary'
export const careTakers = 'Care takers'
export const partners = 'Partners'
export const communityMember = 'Community member'
export const boardMember = 'Board member or association member'
export const dependent = 'Dependent'
export const ndpp = 'NDPP'

export const beneficiaryAndCommunityMembersOutcomes = [
	'Cleared',
	'Sanction N/A',
]

export const dailyWorkersOutcomes = [
	'Cleared',
	'Note to file',
	'Follow up',
	'Oral warning',
	'Written warning',
	'End of collaboration',
	//'NDPP',
]

export const incentiveStaffOrVolunteersOutcomes = [
	'Cleared',
	'Note to file',
	'Follow up',
	'Oral warning',
	'Written warning',
	'Suspension',
	'Demotion',
	'End of collaboration',
	//'NDPP',
]

export const internationalMobileAndHqAndBoardMemberStaffOutcomes = [
	'Cleared',
	'Note to file',
	'Follow up',
	'Oral warning',
	'Written warning',
	'Early Return',
]

export const locallyHiredStaffOutcomes = [
	'Cleared',
	'Note to file',
	'Follow up',
	'Oral warning',
	'Written warning',
	'Suspension',
	'Demotion',
	'End of contract',
]

export const cleared = 'Cleared'
export const noteToFile = 'Note to file'
export const followUp = 'Follow up'
export const oralWarning = 'Oral warning'
export const writtenWarning = 'Written warning'
export const suspension = 'Suspension'
export const demotion = 'Demotion'
export const endOfContract = 'End of contract'
export const endOfCollaborationWithOcb = 'End of collaboration'
//export const ndpp = 'NDPP'
export const earlyReturn = 'Early Return'
export const sanction = 'Sanction N/A'

export const oralWarningOptions = [
	'Cleared',
	'Note to file',
	'Follow up',
	'Oral warning',
]

export const writtenWarningAndEarlyReturnOptions = [
	'Cleared',
	'Note to file',
	'Follow up',
	'Written warning',
	'Early Return',
]

export const suspensionOptions = [
	'Cleared',
	'Note to file',
	'Follow up',
	'Suspension',
]

export const demotionOptions = [
	'Cleared',
	'Note to file',
	'Follow up',
	'Demotion',
	'End of contract',
]

export const endOfContractOptions = [
	'Cleared',
	'Note to file',
	'Follow up',
	'Demotion',
	'End of contract',
	'End of collaboration',
	//'NDPP',
]

export const internationalAndHqStaffGarecAndHrOptions = [
	'Suspension',
	'Demotion',
	'End of contract',
	'End of collaboration',
	//'NDPP',
]

export const boardMemberGarecAndHrOptions = ['End of collaboration']

export const identifiedPerson = 'An identified person'

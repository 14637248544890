import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const LanguageOption = () => {
	const { i18n } = useTranslation()

	const [language, setLanguage] = useState()
	const changeLanguage = (lng) => {
		setLanguage(lng)
		i18n.changeLanguage(lng)
	}
	const getLanguage = () => i18n.language || window.localStorage.i18nextLng

	useEffect(() => {
		setLanguage(getLanguage())
	}, [])
	return (
		<div style={{ paddingRight: '10px' }}>
			<select
				onChange={({ target: { value } }) => changeLanguage(value)}
				value={language}
			>
				{/* <option value="ar">العربية</option> */}
				<option value="en">English</option>
				{/* <option value="es">Español</option>
				<option value="fr">Français</option>
				<option value="pt">Portugués</option> */}
			</select>
		</div>
	)
}

export default LanguageOption

import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { useTranslation } from 'react-i18next'

import { Box, Button, Typography, Modal, TextField } from '@mui/material/'
import Progress from '../../UI/Progress'

const REMOVE_REPORT = loader('./graphql/removeReport.graphql')

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
}
const buttonStyle = { backgroundColor: '#FD0000', marginTop: '10px' }
const DeleteModal = ({ show, toggleShow, reportId }) => {
	const [removeNote, setRemoveNote] = useState('')
	const { t } = useTranslation()

	const [removeReport, { data, loading }] = useMutation(REMOVE_REPORT)

	const handleRemoveReport = () => {
		removeReport({
			variables: {
				input: {
					id: reportId,
					removeNote: removeNote,
				},
			},
		})
	}

	return (
		<div>
			<Modal
				open={show}
				onClose={toggleShow}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					{loading ? (
						<Progress />
					) : data && data?.removeReport?.removed ? (
						<>
							<Typography id="modal-modal-title" variant="h6" component="h2">
								{t('success_remove_msg')}
							</Typography>
							<Button
								variant="contained"
								onClick={() => window.location.reload()}
								style={buttonStyle}
							>
								{t('close')}
							</Button>
						</>
					) : (
						<>
							{' '}
							<Typography id="modal-modal-title" variant="h6" component="h2">
								{t('remove_msg')}
							</Typography>
							<TextField
								style={{ marginTop: '10px' }}
								fullWidth
								label={t('note')}
								name="affectedPerson_CreateHrStatus"
								placeholder={t('note')}
								value={removeNote}
								onChange={({ target }) => setRemoveNote(target.value.trim())}
								required
							/>
							<Button
								variant="contained"
								style={buttonStyle}
								//Disable if user did not write a text as a note
								disabled={!Boolean(removeNote.length)}
								onClick={handleRemoveReport}
							>
								{t('delete')}
							</Button>
						</>
					)}
				</Box>
			</Modal>
		</div>
	)
}
export default DeleteModal

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useCurrentUser } from '../../CurrentUserContext'
import { useTranslation } from 'react-i18next'
import { Button, Menu, MenuItem } from '@mui/material'

import {
	isCellUser,
	isCoordoUser,
	isFieldUser,
} from '../../pages/ReportForm/functions'
import useStyles from './useStyles'

const ReportMenuOptions = () => {
	const currentUser = useCurrentUser()
	const { t } = useTranslation()
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const cellUser = currentUser && isCellUser({ currentUser })
	const isCoordo = currentUser && isCoordoUser({ currentUser })
	const isField = currentUser && isFieldUser({ currentUser })
	const { styledButton, styledLink } = useStyles
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<div>
			<Button
				style={styledButton}
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
			>
				{t('report')}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<MenuItem>
					<Link to="/create-report" style={styledLink}>
						{t('new_report')}
					</Link>
				</MenuItem>
				<MenuItem>
					<Link to="/my-reports" style={styledLink}>
						{t('my_reports')}
					</Link>
				</MenuItem>
				{
					//This link should be visible for all users except HRO users
					cellUser ? null : (
						<MenuItem>
							<Link to="/project-reports" style={styledLink}>
								{t('project_reports')}
							</Link>
						</MenuItem>
					)
				}
				{
					//This link should be visible for all users except field users with no coordo position
					isField || cellUser ? null : (
						<MenuItem>
							<Link to="/mission-reports" style={styledLink}>
								{t('mission_reports')}
							</Link>
						</MenuItem>
					)
				}
				{
					//This link should be visible for all users except field users with no coordo position
					isField || isCoordo ? null : (
						<MenuItem>
							<Link to="/cell-reports" style={styledLink}>
								{t('cell_reports')}
							</Link>
						</MenuItem>
					)
				}
				{currentUser.garec && (
					<MenuItem>
						<Link to="/garec-reports" style={styledLink}>
							{t('garec_reports')}
						</Link>
					</MenuItem>
				)}
				{(currentUser.garec || currentUser.behaviourFocalPoint) && (
					<MenuItem>
						<Link to="/all-reports" style={styledLink}>
							{t('all_reports')}
						</Link>
					</MenuItem>
				)}
			</Menu>
		</div>
	)
}

export default ReportMenuOptions

import React, { useContext } from 'react'
import { AppContext } from '../Context'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'

const NextButton = ({ error, number, addSubject }) => {
	const { t } = useTranslation()
	const { handleNext } = useContext(AppContext)
	return (
		<Button
			variant="contained"
			sx={{ mt: 3, ml: 1 }}
			disabled={error}
			style={{ backgroundColor: error ? '#E0E0E0' : '#FD0000' }}
			onClick={
				!error
					? () =>
							handleNext({
								number,
								addSubject,
							})
					: () => null
			}
		>
			{t('next')}
		</Button>
	)
}

export default NextButton

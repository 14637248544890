export const generalInfoInitialValues = [
	{ name: 'generalInfo_reportDate', mainKey: 'reportDate', subKey: 'date' },
	{ name: 'generalInfo_incidentMission', mainKey: 'mission', subKey: 'code' },
	{ name: 'generalInfo_incidentProject', mainKey: 'project', subKey: 'code' },
	{ name: 'generalInfo_caseReportedTo', mainKey: 'reportedTo', subKey: 'id' },
	{
		name: 'generalInfo_investigationUndertaken',
		mainKey: 'invistegationTaken',
		subKey: false,
	},
	{
		name: 'generalInfo_investigatedBy',
		mainKey: 'investigatedBy',
		subKey: 'id',
	},
	{
		name: 'generalInfo_noInvestigationReason',
		mainKey: 'notTakenInvistigationReason',
		subKey: 'id',
	},
	{
		name: 'generalInfo_adviceAskedByGarec',
		mainKey: 'adviceRequestedFromGarec',
		subKey: false,
	},
	{
		name: 'generalInfo_complainantStatus',
		mainKey: 'complainantStatus',
		subKey: 'id',
	},
	{
		name: 'generalInfo_affectedPersonIdentified',
	},
]

export const complainantInitialValues = [
	{ name: 'complainant_Gender', mainKey: 'gender', subKey: 'id' },
	{ name: 'complainant_HrStatus', mainKey: 'hrStatus', subKey: 'id' },
	{ name: 'complainant_Position', mainKey: 'position', subKey: 'id' },
	{
		name: 'complainant_ContractingOffice',
		mainKey: 'contractingOffice',
		subKey: 'id',
	},
	{
		name: 'complainant_MsfExperience',
		mainKey: 'experience',
		subKey: 'id',
	},
	{
		name: 'complainant_IsMinor',
		mainKey: 'isMinor',
		subKey: false,
	},
	{
		name: 'complainant_Nationality',
		mainKey: 'nationality',
		subKey: 'id',
	},
	{
		name: 'complainant_IsTheEffectedPerson',
		mainKey: 'affectedPerson',
		subKey: false,
	},
]

export const affectedPersonInitialValues = [
	{ name: 'affectedPerson_Gender', mainKey: 'gender', subKey: 'id' },
	{ name: 'affectedPerson_HrStatus', mainKey: 'hrStatus', subKey: 'id' },
	{ name: 'affectedPerson_Position', mainKey: 'position', subKey: 'id' },
	{
		name: 'affectedPerson_ContractingOffice',
		mainKey: 'contractingOffice',
		subKey: 'id',
	},
	{
		name: 'affectedPerson_MsfExperience',
		mainKey: 'experience',
		subKey: 'id',
	},
	{
		name: 'affectedPerson_IsMinor',
		mainKey: 'isMinor',
		subKey: false,
	},
	{
		name: 'affectedPerson_Nationality',
		mainKey: 'nationality',
		subKey: 'id',
	},
]

export const subjectInitialValues = [
	{ name: 'subject_Gender', mainKey: 'gender', subKey: 'id' },
	{ name: 'subject_HrStatus', mainKey: 'hrStatus', subKey: 'id' },
	{ name: 'subject_Position', mainKey: 'position', subKey: 'id' },
	{
		name: 'subject_ContractingOffice',
		mainKey: 'contractingOffice',
		subKey: 'id',
	},
	{
		name: 'subject_MsfExperience',
		mainKey: 'experience',
		subKey: 'id',
	},
	{
		name: 'subject_IsMinor',
		mainKey: 'isMinor',
		subKey: false,
	},
	{
		name: 'subject_Nationality',
		mainKey: 'nationality',
		subKey: 'id',
	},
	{
		name: 'subject_caseIpob',
		mainKey: 'ipobTypologies',
		subKey: 'array',
	},
]

export const conclusionInitialValues = [
	{ name: 'conclusion_PerIpob', mainKey: 'ipobTypologies', subKey: 'array' },
	{ name: 'conclusion_SubjectOutcome', mainKey: 'outcomes', subKey: 'array' },
	{
		name: 'conclusion_SubjectOutcomeHq',
		mainKey: 'outcomesHq',
		subKey: 'array',
	},
	{
		name: 'conclusion_SubjectLegalIssues',
		mainKey: 'legalStatutory',
		subKey: 'string',
	},
	{
		name: 'conclusion_AuthoritiesInvolvement',
		mainKey: 'authorityInvolved',
		subKey: 'string',
	},
]

export const closureInitialValues = [
	{ name: 'closure_ClosingDate', mainKey: 'caseClosedDate' },
	{
		name: 'closure_WhereFactsFound',
		mainKey: 'whereFactsCanBeFound',
		subKey: 'id',
	},
	{
		name: 'closure_Comment',
		mainKey: 'comment',
	},
]

import React from 'react'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'
import { Button } from '@mui/material'
import style from './useStyles'

const LoginButton = () => {
	const { t } = useTranslation()
	const currentUser = useCurrentUser()

	//Remove all local stored values and cookies on logout
	const handleLogOutCleaningSession = () => {
		if (currentUser) {
			localStorage.clear()
			Cookies.remove('connect.sid')
		}
	}

	return (
		<form
			method={currentUser ? 'post' : 'get'}
			action={currentUser ? '/logout' : '/login'}
		>
			<Button
				variant="contained"
				color="secondary"
				style={style}
				onClick={handleLogOutCleaningSession}
				type="submit"
			>
				{currentUser ? t('sign_out') : t('sign_in')}
			</Button>
		</form>
	)
}

export default LoginButton

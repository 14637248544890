import React from 'react'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'
import { Container } from '@mui/material'
import Header from '../../UI/Header'
import useStyles from './useStyles'
import msfLogo from '../../../images/msflogo-trans-background.png'

const Landing = () => {
	const currentUser = useCurrentUser()
	const { t } = useTranslation()
	const { MuiContainer } = useStyles()
	if (currentUser) return <Navigate to={'/home'} />
	return (
		<>
			<Header />
			<h1>{t('welcome_msg')}</h1>
			<div style={{ padding: '10px 0 0' }}>
				<img src={msfLogo} alt="msf-logo" style={{ width: '20%' }} />
			</div>
			{/* <Container maxWidth="md" className={MuiContainer}>
				<h3>{t('about')}</h3>
				<p>{t('about_text')}</p>
				<p>{t('guidelines')}</p>
				<p>
					{t('contact_text')}
					<a href="mailto: BRS-support@brussels.msf.org">
						BRS-support@brussels.msf.org
					</a>
				</p>
			</Container> */}
		</>
	)
}

export default Landing

import React, { useEffect, useState, useCallback, useContext } from 'react'
import { AppContext } from './Context'
import { useTranslation } from 'react-i18next'
//import { useCurrentUser } from '../../CurrentUserContext'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import BackButton from './ReusableInputs/BackButton'
import NextButton from './ReusableInputs/NextButton'
import {
	Box,
	FormControl,
	TextField,
	Grid,
	MenuItem,
	InputLabel,
	FormLabel,
	Select,
} from '@mui/material'
import RadioGroupInput from './ReusableInputs/RadioGroupInput'
import { affectedPersonInitialValues } from '../EditReportForm/initialValues'
import { isFieldPosition, isHqPosition, sortAlphabetically } from './functions'

const ALL_COUNTRIES = loader('./graphql/allCountries.graphql')
const EXPERIENCES = loader('./graphql/experiences.graphql')
const FIELD_POSITIONS = loader('./graphql/fieldPositions.graphql')
const GENDERS = loader('./graphql/genders.graphql')
const HR_STATUS = loader('./graphql/hrStatusInitialOptions.graphql')
const HQ_POSITIONS = loader('./graphql/hqPositions.graphql')

const AffectedPerson = ({ report }) => {
	const { t } = useTranslation()
	const [hrStatusArr, setHrStatusArr] = useState()
	//const currentUser = useCurrentUser()
	const { formValues, handleChange, variant, margin, yesAndNoRadioOptions } =
		useContext(AppContext)
	const { data: { allCountries = null } = {} } = useQuery(ALL_COUNTRIES)
	const { data: { experiences = null } = {} } = useQuery(EXPERIENCES)
	const { data: { fieldPositions = null } = {} } = useQuery(FIELD_POSITIONS)
	const { data: { genders = null } = {} } = useQuery(GENDERS)
	const { data: { hrStatusInitialOptions = null } = {} } = useQuery(HR_STATUS)
	const { data: { hqPositions = null } = {} } = useQuery(HQ_POSITIONS)

	const {
		affectedPerson_Gender,
		affectedPerson_HrStatus,
		affectedPerson_CreateHrStatus,
		affectedPerson_Position,
		affectedPerson_ContractingOffice,
		affectedPerson_MsfExperience,
		affectedPerson_IsMinor,
		affectedPerson_Nationality,
		generalInfo_affectedPersonIdentified,
	} = formValues
	// Check if all values are not empty and if there are some errors
	const isError = useCallback(
		() =>
			// Using Object.keys to get an array of property names from an object
			Object.keys({
				affectedPerson_Gender,
				affectedPerson_HrStatus,
				affectedPerson_CreateHrStatus,
				affectedPerson_Position,
				affectedPerson_ContractingOffice,
				affectedPerson_MsfExperience,
				affectedPerson_IsMinor,
				affectedPerson_Nationality,
			}).some(
				(name) =>
					(formValues[name]?.required && !formValues[name]?.value) ||
					formValues[name]?.error ||
					(affectedPerson_HrStatus?.value === 'other' &&
						!affectedPerson_CreateHrStatus.value?.trim()?.length) ||
					((isFieldPosition({
						choosenHrStatusId: affectedPerson_HrStatus?.value,
						hrStatusArr,
					}) ||
						isHqPosition({
							choosenHrStatusId: affectedPerson_HrStatus?.value,
							hrStatusArr,
						})) &&
						(!affectedPerson_Position?.value ||
							!affectedPerson_ContractingOffice?.value ||
							!affectedPerson_MsfExperience?.value))
			),
		[
			formValues,
			affectedPerson_Gender,
			affectedPerson_HrStatus,
			affectedPerson_CreateHrStatus,
			affectedPerson_Position,
			affectedPerson_ContractingOffice,
			affectedPerson_MsfExperience,
			affectedPerson_IsMinor,
			affectedPerson_Nationality,
		]
	)

	//Here we set hrStatusArr once the component loads
	//the reason we write this into a state, is we have fixed list of values as options
	//for the user when submitting a record, but user can enter a status manually
	//and create an entry in the DB & this values should be related to this report only
	useEffect(() => {
		if (hrStatusInitialOptions) {
			if (report && report.affectedPerson) {
				const find = hrStatusInitialOptions.find(
					({ id }) => id === report.affectedPerson.hrStatus.id
				)
				if (find) {
					setHrStatusArr([
						...hrStatusInitialOptions,
						{ id: 'other', status: 'other' },
					])
				} else {
					setHrStatusArr([
						...hrStatusInitialOptions,
						report.affectedPerson.hrStatus,
						{ id: 'other', status: 'other' },
					])
				}
			} else {
				setHrStatusArr([
					...hrStatusInitialOptions,
					{ id: 'other', status: 'other' },
				])
			}
		}
	}, [hrStatusInitialOptions, report])

	/**
	 *In this case user is editing the form, we go over the form values and overwrite to the
	 *initial values we exported earlier
	 */
	useEffect(() => {
		if (report) {
			affectedPersonInitialValues.map(({ name, mainKey, subKey }) => {
				const { affectedPerson } = report
				if (affectedPerson) {
					//If The key === 'affectedPerson_Position' means we need to know the position of this
					//user was field or hq as we have one key 'affectedPerson_Position' handles both cases
					//Either fieldPosition or hqPosition can be true
					if (name === 'affectedPerson_Position') {
						handleChange({
							target: {
								name: name,
								value: affectedPerson.fieldPosition
									? affectedPerson.fieldPosition.id
									: affectedPerson.hqPosition
									? affectedPerson.hqPosition.id
									: '',
							},
						})
					} else if (subKey) {
						handleChange({
							target: {
								name: name,
								value: affectedPerson[mainKey]
									? affectedPerson[mainKey][subKey]
									: affectedPerson[mainKey],
							},
						})
						//If subKey is false, it means there is no subkey and we need to use the mainKey directly
						//This works in the case of values === Boolean
					} else if (!subKey) {
						handleChange({
							target: {
								name: name,
								value: Boolean(report[mainKey]),
							},
						})
					}
				}
			})
		}
	}, [report])

	return (
		<>
			<h2>{t('affected_person')}</h2>
			{hrStatusArr && (
				<Grid
					container
					spacing={2}
					direction="column"
					justifyContent="center"
					alignItems="left"
					columns={16}
				>
					<Grid item xs={12}>
						<FormControl fullWidth required={affectedPerson_Gender.required}>
							<InputLabel id="demo-simple-select-label">
								{t('affectedPerson_gender')}
							</InputLabel>
							<Select
								id="affectedPerson_Gender"
								value={affectedPerson_Gender.value}
								name="affectedPerson_Gender"
								label={t('affectedPerson_gender')}
								onChange={handleChange}
							>
								{genders &&
									genders.map(({ id, gender }) => (
										<MenuItem value={id} key={id}>
											{t(gender)}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth required={affectedPerson_HrStatus.required}>
							<InputLabel id="demo-simple-select-label">
								{t('affectedPerson_hrStatus')}
							</InputLabel>
							<Select
								id="affectedPerson_HrStatus"
								value={affectedPerson_HrStatus.value}
								name="affectedPerson_HrStatus"
								label={t('affectedPerson_hrStatus')}
								onChange={handleChange}
							>
								{hrStatusArr &&
									hrStatusArr.map(({ status, id }) => (
										<MenuItem value={id} key={id}>
											{t(status)}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>
					{affectedPerson_HrStatus.value === 'other' ? (
						<Grid item xs={12} sm={6}>
							<TextField
								variant={variant}
								margin={margin}
								fullWidth
								label={t('other')}
								name="affectedPerson_CreateHrStatus"
								placeholder={t('other')}
								value={affectedPerson_CreateHrStatus.value}
								onChange={handleChange}
								required={affectedPerson_HrStatus.value === 'other'}
							/>
						</Grid>
					) : null}
					{affectedPerson_HrStatus.value !== '' &&
						isFieldPosition({
							choosenHrStatusId: affectedPerson_HrStatus.value,
							hrStatusArr,
						}) && (
							<Grid item xs={12}>
								<FormControl
									fullWidth
									required={isFieldPosition({
										choosenHrStatusId: affectedPerson_HrStatus.value,
										hrStatusArr,
									})}
								>
									<InputLabel id="demo-simple-select-label">
										{t('affectedPerson_field_position')}
									</InputLabel>
									<Select
										id="affectedPerson_Position"
										value={affectedPerson_Position.value}
										name="affectedPerson_Position"
										label={t('affectedPerson_field_position')}
										onChange={handleChange}
									>
										{fieldPositions &&
											fieldPositions.map(({ id, position }) => (
												<MenuItem value={id} key={id}>
													{t(position)}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Grid>
						)}

					{affectedPerson_HrStatus.value !== '' &&
						isHqPosition({
							choosenHrStatusId: affectedPerson_HrStatus.value,
							hrStatusArr,
						}) && (
							<Grid item xs={12}>
								<FormControl
									fullWidth
									required={isHqPosition({
										choosenHrStatusId: affectedPerson_HrStatus.value,
										hrStatusArr,
									})}
								>
									<InputLabel id="demo-simple-select-label">
										{t('affectedPerson_hq_position')}
									</InputLabel>
									<Select
										id="affectedPerson_Position"
										value={affectedPerson_Position.value}
										name="affectedPerson_Position"
										label={t('affectedPerson_hq_position')}
										onChange={handleChange}
									>
										{hqPositions &&
											hqPositions.map(({ id, position }) => (
												<MenuItem value={id} key={id}>
													{t(position)}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Grid>
						)}
					{affectedPerson_HrStatus.value !== '' &&
						(isFieldPosition({
							choosenHrStatusId: affectedPerson_HrStatus.value,
							hrStatusArr,
						}) ||
							isHqPosition({
								choosenHrStatusId: affectedPerson_HrStatus.value,
								hrStatusArr,
							})) && (
							<>
								<Grid item xs={12}>
									<FormControl
										fullWidth
										required={
											isFieldPosition({
												choosenHrStatusId: affectedPerson_HrStatus.value,
												hrStatusArr,
											}) ||
											isHqPosition({
												choosenHrStatusId: affectedPerson_HrStatus.value,
												hrStatusArr,
											})
										}
									>
										<InputLabel id="demo-simple-select-label">
											{t('affectedPerson_contracting_office')}
										</InputLabel>
										<Select
											id="affectedPersonContractingOfficeId"
											value={affectedPerson_ContractingOffice.value}
											name="affectedPerson_ContractingOffice"
											label={t('affectedPerson_contracting_office')}
											onChange={handleChange}
										>
											{allCountries &&
												sortAlphabetically({
													arr: allCountries,
													sortOn: 'country',
												}).map(({ id, country }) => (
													<MenuItem value={id} key={id}>
														{country}
													</MenuItem>
												))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<FormControl
										fullWidth
										required={
											isFieldPosition({
												choosenHrStatusId: affectedPerson_HrStatus.value,
												hrStatusArr,
											}) ||
											isHqPosition({
												choosenHrStatusId: affectedPerson_HrStatus.value,
												hrStatusArr,
											})
										}
									>
										<InputLabel id="demo-simple-select-label">
											{t('affectedPerson_msf_experience')}
										</InputLabel>
										<Select
											id="affectedPerson_MsfExperience"
											value={affectedPerson_MsfExperience.value}
											name="affectedPerson_MsfExperience"
											label={t('affectedPerson_msf_experience')}
											onChange={handleChange}
										>
											{experiences &&
												experiences.map(({ id, experience }) => (
													<MenuItem value={id} key={id}>
														{t(experience)}
													</MenuItem>
												))}
										</Select>
									</FormControl>
								</Grid>
							</>
						)}
					{affectedPerson_HrStatus.value === 'other' &&
					affectedPerson_CreateHrStatus.value === '' ? null : (
						<>
							<Grid item xs={12}>
								<FormControl>
									<FormLabel id="demo-radio-buttons-group-label">
										{t('affectedPerson_minor')}
									</FormLabel>
									<RadioGroupInput
										id="affectedPerson_IsMinor"
										name="affectedPerson_IsMinor"
										value={affectedPerson_IsMinor.value}
										array={yesAndNoRadioOptions}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl
									fullWidth
									required={affectedPerson_Nationality.required}
								>
									<InputLabel id="demo-simple-select-label">
										{t('affectedPerson_nationality')}
									</InputLabel>
									<Select
										id="affectedPerson_Nationality"
										value={affectedPerson_Nationality.value}
										name="affectedPerson_Nationality"
										label={t('affectedPerson_nationality')}
										onChange={handleChange}
									>
										{allCountries &&
											sortAlphabetically({
												arr: allCountries,
												sortOn: 'country',
											}).map(({ id, country }) => (
												<MenuItem value={id} key={id}>
													{country}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Grid>
						</>
					)}
				</Grid>
			)}

			<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<BackButton
					number={generalInfo_affectedPersonIdentified.value === true ? 2 : 1}
				/>

				<NextButton error={isError()} number={1} />
			</Box>
		</>
	)
}

export default AffectedPerson

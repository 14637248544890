import React, { useEffect, useContext, useCallback, useState } from 'react'
import { AppContext } from './Context'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import BackButton from './ReusableInputs/BackButton'
import NextButton from './ReusableInputs/NextButton'

import {
	Box,
	FormControl,
	TextField,
	Grid,
	MenuItem,
	InputLabel,
	FormLabel,
	Select,
	LinearProgress,
} from '@mui/material'
import RadioGroupInput from './ReusableInputs/RadioGroupInput'
import { complainantInitialValues } from '../EditReportForm/initialValues'
import { isFieldPosition, isHqPosition, sortAlphabetically } from './functions'

const ALL_COUNTRIES = loader('./graphql/allCountries.graphql')
const EXPERIENCES = loader('./graphql/experiences.graphql')
const FIELD_POSITIONS = loader('./graphql/fieldPositions.graphql')
const GENDERS = loader('./graphql/genders.graphql')
const MISSIONS = loader('./graphql/missions.graphql')
const HR_STATUS = loader('./graphql/hrStatusInitialOptions.graphql')
const HQ_POSITIONS = loader('./graphql/hqPositions.graphql')

const Complainant = ({ report }) => {
	const { t } = useTranslation()
	// const currentUser = useCurrentUser()
	const [hrStatusArr, setHrStatusArr] = useState()
	const { formValues, handleChange, variant, margin, yesAndNoRadioOptions } =
		useContext(AppContext)

	const { data: { allCountries = null } = {}, loading: allCountriesLoading } =
		useQuery(ALL_COUNTRIES)
	const { data: { experiences = null } = {}, loading: experienceLoading } =
		useQuery(EXPERIENCES)
	const { data: { fieldPositions = null } = {}, loading: fieldsPosLoading } =
		useQuery(FIELD_POSITIONS)
	const { data: { genders = null } = {}, loading: gendersLoading } =
		useQuery(GENDERS)
	const {
		data: { hrStatusInitialOptions = null } = {},
		loading: hrStatusLoading,
	} = useQuery(HR_STATUS)
	const { data: { hqPositions = null } = {}, loading: hqPoistionLoading } =
		useQuery(HQ_POSITIONS)
	const { data: { missions = null } = {}, loading: missionsLoading } =
		useQuery(MISSIONS)
	const {
		complainant_Gender,
		complainant_HrStatus,
		complainant_CreateHrStatus,
		complainant_Position,
		complainant_ContractingOffice,
		complainant_MsfExperience,
		complainant_IsMinor,
		complainant_Nationality,
		complainant_IsTheEffectedPerson,
	} = formValues
	// Check if all values are not empty and if there are some errors
	const isError = useCallback(
		() =>
			// The function checks if any of the specified conditions are true for the form fields.
			Object.keys({
				complainant_Gender,
				complainant_HrStatus,
				complainant_CreateHrStatus,
				complainant_Position,
				complainant_ContractingOffice,
				complainant_MsfExperience,
				complainant_IsMinor,
				complainant_Nationality,
				complainant_IsTheEffectedPerson,
			}).some(
				(name) =>
					// Check if any of the specified conditions are true for at least one form field.
					// Conditions include required field not filled, presence of an error, and other specific conditions.
					(formValues[name]?.required && !formValues[name]?.value) ||
					// Check if there is an error for any of the form fields.
					formValues[name]?.error ||
					// Check additional conditions based on complainant_HrStatus value.
					(complainant_HrStatus?.value === 'other' &&
						!complainant_CreateHrStatus?.value?.trim()?.length) ||
					// Check additional conditions based on complainant_HrStatus and other fields.
					((isFieldPosition({
						choosenHrStatusId: complainant_HrStatus?.value,
						hrStatusArr,
					}) ||
						isHqPosition({
							choosenHrStatusId: complainant_HrStatus?.value,
							hrStatusArr,
						})) &&
						(!complainant_Position?.value ||
							!complainant_ContractingOffice?.value ||
							!complainant_MsfExperience?.value))
			),
		// Dependencies for the useCallback function, triggering a re-render only when these values change.
		[
			formValues,
			complainant_Gender,
			complainant_HrStatus,
			complainant_CreateHrStatus,
			complainant_Position,
			complainant_ContractingOffice,
			complainant_MsfExperience,
			complainant_IsMinor,
			complainant_Nationality,
			complainant_IsTheEffectedPerson,
		]
	)

	//Here we set hrStatusArr once the component loads
	//the reason we write this into a state, is we have fixed list of values as options
	//for the user when submitting a record, but user can enter a status manually
	//and create an entry in the DB & this values should be related to this report only
	useEffect(() => {
		if (hrStatusInitialOptions) {
			if (report && report.complainant) {
				const find = hrStatusInitialOptions.find(
					({ id }) => id === report.complainant.hrStatus.id
				)
				if (find) {
					setHrStatusArr([
						...hrStatusInitialOptions,
						{ id: 'other', status: 'other' },
					])
				} else {
					setHrStatusArr([
						...hrStatusInitialOptions,
						report.complainant.hrStatus,
						{ id: 'other', status: 'other' },
					])
				}
			} else {
				setHrStatusArr([
					...hrStatusInitialOptions,
					{ id: 'other', status: 'other' },
				])
			}
		}
	}, [hrStatusInitialOptions, report])

	/**
	 *In this case user is editing the form, we go over the form values and overwrite to the
	 *initial values we exported earlier
	 */
	useEffect(() => {
		if (report) {
			complainantInitialValues.map(({ name, mainKey, subKey }) => {
				const { complainant } = report
				if (complainant) {
					//If The key === 'complainant_Position' means we need to know the position of this
					//user was field or hq as we have one key 'complainant_Position' handles both cases
					//Either fieldPosition or hqPosition can be true
					if (name === 'complainant_Position') {
						handleChange({
							target: {
								name: name,
								value: complainant.fieldPosition
									? complainant.fieldPosition.id
									: complainant.hqPosition
									? complainant.hqPosition.id
									: '',
							},
						})
					} else if (name === 'complainant_IsTheEffectedPerson') {
						//Hanlde special case if the complainant is the affectedPerson
						//report.affectedPerson should be null
						//If report.affectedPerson  === null so the value of 'complainant_IsTheEffectedPerson' should be `true`
						handleChange({
							target: {
								name: name,
								value: !Boolean(report.affectedPerson),
							},
						})
					} else if (subKey) {
						handleChange({
							target: {
								name: name,
								value: complainant[mainKey]
									? complainant[mainKey][subKey]
									: complainant[mainKey],
							},
						})
						//If subKey is false, it means there is no subkey and we need to use the mainKey directly
						//This works in the case of values === Boolean
					} else if (!subKey) {
						handleChange({
							target: {
								name: name,
								value: Boolean(report[mainKey]),
							},
						})
					}
				}
			})
		}
	}, [report])

	return (
		<>
			<h2>{t('complainant')}</h2>
			{hrStatusArr && (
				<Grid
					container
					spacing={2}
					direction="column"
					justifyContent="center"
					alignItems="left"
					columns={16}
				>
					<Grid item xs={12}>
						<FormControl fullWidth required={complainant_Gender.required}>
							<InputLabel id="demo-simple-select-label">
								{t('complainant_gender')}
							</InputLabel>
							{gendersLoading ? (
								<LinearProgress color="inherit" />
							) : (
								<Select
									id="complainant_Gender"
									value={complainant_Gender.value}
									name="complainant_Gender"
									label={t('complainant_gender')}
									onChange={handleChange}
								>
									{genders &&
										genders.map(({ id, gender }) => (
											<MenuItem value={id} key={id}>
												{t(gender)}
											</MenuItem>
										))}
								</Select>
							)}
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth required={complainant_HrStatus.required}>
							<InputLabel id="demo-simple-select-label">
								{t('complainant_hrStatus')}
							</InputLabel>
							{hrStatusLoading ? (
								<LinearProgress color="inherit" />
							) : (
								<Select
									id="complainant_HrStatus"
									value={complainant_HrStatus.value}
									name="complainant_HrStatus"
									label={t('complainant_hrStatus')}
									onChange={handleChange}
								>
									{
										//Add 'Other' to the the list
										hrStatusArr &&
											hrStatusArr.map(({ status, id }) => (
												<MenuItem value={id} key={id}>
													{t(status)}
												</MenuItem>
											))
									}
								</Select>
							)}
						</FormControl>
					</Grid>
					{complainant_HrStatus.value === 'other' ? (
						<Grid item xs={12} sm={6}>
							<TextField
								variant={variant}
								margin={margin}
								fullWidth
								label={t('other')}
								name="complainant_CreateHrStatus"
								placeholder={t('other')}
								value={complainant_CreateHrStatus.value}
								onChange={handleChange}
								required={complainant_HrStatus.value === 'other'}
							/>
						</Grid>
					) : null}
					{complainant_HrStatus.value !== '' &&
						isFieldPosition({
							choosenHrStatusId: complainant_HrStatus.value,
							hrStatusArr: hrStatusArr,
						}) && (
							<Grid item xs={12}>
								<FormControl
									fullWidth
									required={isFieldPosition({
										choosenHrStatusId: complainant_HrStatus.value,
										hrStatusArr: hrStatusArr,
									})}
								>
									<InputLabel id="demo-simple-select-label">
										{t('complainant_field_position')}
									</InputLabel>
									{fieldsPosLoading ? (
										<LinearProgress color="inherit" />
									) : (
										<Select
											id="complainant_Position"
											value={complainant_Position.value}
											name="complainant_Position"
											label={t('complainant_field_position')}
											onChange={handleChange}
										>
											{fieldPositions &&
												fieldPositions.map(({ id, position }) => (
													<MenuItem value={id} key={id}>
														{t(position)}
													</MenuItem>
												))}
										</Select>
									)}
								</FormControl>
							</Grid>
						)}

					{complainant_HrStatus.value !== '' &&
						isHqPosition({
							choosenHrStatusId: complainant_HrStatus.value,
							hrStatusArr: hrStatusArr,
						}) && (
							<Grid item xs={12}>
								<FormControl
									fullWidth
									required={isHqPosition({
										choosenHrStatusId: complainant_HrStatus.value,
										hrStatusArr: hrStatusArr,
									})}
								>
									<InputLabel id="demo-simple-select-label">
										{t('complainant_hq_position')}
									</InputLabel>
									{hqPoistionLoading ? (
										<LinearProgress color="inherit" />
									) : (
										<Select
											id="complainant_Position"
											value={complainant_Position.value}
											name="complainant_Position"
											label={t('complainant_hq_position')}
											onChange={handleChange}
										>
											{hqPositions &&
												hqPositions.map(({ id, position }) => (
													<MenuItem value={id} key={id}>
														{t(position)}
													</MenuItem>
												))}
										</Select>
									)}
								</FormControl>
							</Grid>
						)}
					{complainant_HrStatus.value !== '' &&
						(isFieldPosition({
							choosenHrStatusId: complainant_HrStatus.value,
							hrStatusArr: hrStatusArr,
						}) ||
							isHqPosition({
								choosenHrStatusId: complainant_HrStatus.value,
								hrStatusArr: hrStatusArr,
							})) && (
							<>
								<Grid item xs={12}>
									<FormControl
										fullWidth
										required={
											isFieldPosition({
												choosenHrStatusId: complainant_HrStatus.value,
												hrStatusArr: hrStatusArr,
											}) ||
											isHqPosition({
												choosenHrStatusId: complainant_HrStatus.value,
												hrStatusArr: hrStatusArr,
											})
										}
									>
										<InputLabel id="demo-simple-select-label">
											{t('complainant_contracting_office')}
										</InputLabel>
										{allCountriesLoading ? (
											<LinearProgress color="inherit" />
										) : (
											<Select
												id="complainant_ContractingOfficeId"
												value={complainant_ContractingOffice.value}
												name="complainant_ContractingOffice"
												label={t('complainant_contracting_office')}
												onChange={handleChange}
											>
												{allCountries &&
													sortAlphabetically({
														arr: allCountries,
														sortOn: 'country',
													}).map(({ id, country }) => (
														<MenuItem value={id} key={id}>
															{country}
														</MenuItem>
													))}
											</Select>
										)}
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<FormControl
										fullWidth
										required={
											isFieldPosition({
												choosenHrStatusId: complainant_HrStatus.value,
												hrStatusArr: hrStatusArr,
											}) ||
											isHqPosition({
												choosenHrStatusId: complainant_HrStatus.value,
												hrStatusArr: hrStatusArr,
											})
										}
									>
										<InputLabel id="demo-simple-select-label">
											{t('complainant_msf_experience')}
										</InputLabel>
										{experienceLoading ? (
											<LinearProgress color="inherit" />
										) : (
											<Select
												id="complainant_MsfExperience"
												value={complainant_MsfExperience.value}
												name="complainant_MsfExperience"
												label={t('complainant_msf_experience')}
												onChange={handleChange}
											>
												{experiences &&
													experiences.map(({ id, experience }) => (
														<MenuItem value={id} key={id}>
															{t(experience)}
														</MenuItem>
													))}
											</Select>
										)}
									</FormControl>
								</Grid>
							</>
						)}
					{complainant_HrStatus.value === 'other' &&
					complainant_CreateHrStatus.value === '' ? null : (
						<>
							<Grid item xs={12}>
								<FormControl>
									<FormLabel id="demo-radio-buttons-group-label">
										{t('complainant_minor')}
									</FormLabel>
									<RadioGroupInput
										id="complainant_IsMinor"
										name="complainant_IsMinor"
										value={complainant_IsMinor.value}
										array={yesAndNoRadioOptions}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl
									fullWidth
									required={complainant_Nationality.required}
								>
									<InputLabel id="demo-simple-select-label">
										{t('complainant_nationality')}
									</InputLabel>
									{allCountriesLoading ? (
										<LinearProgress color="inherit" />
									) : (
										<Select
											id="complainant_Nationality"
											value={complainant_Nationality.value}
											name="complainant_Nationality"
											label={t('complainant_nationality')}
											onChange={handleChange}
										>
											{allCountries &&
												sortAlphabetically({
													arr: allCountries,
													sortOn: 'country',
												}).map(({ id, country }) => (
													<MenuItem value={id} key={id}>
														{country}
													</MenuItem>
												))}
										</Select>
									)}
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl>
									<FormLabel id="demo-radio-buttons-group-label">
										{t('is_complainant_effected_person')}
									</FormLabel>
									<RadioGroupInput
										id="complainant_IsTheEffectedPerson"
										name="complainant_IsTheEffectedPerson"
										value={complainant_IsTheEffectedPerson.value}
										array={yesAndNoRadioOptions}
									/>
								</FormControl>
							</Grid>
						</>
					)}
				</Grid>
			)}

			<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<BackButton number={1} />
				<NextButton
					error={isError()}
					number={complainant_IsTheEffectedPerson.value ? 2 : 1}
				/>
			</Box>
		</>
	)
}

export default Complainant
